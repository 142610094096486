import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/th';
import 'moment/locale/en-sg';
import { LANGUAGE_LIST } from 'constants/default-config';

const languageDetector = new LanguageDetector();

const languageDetectorOptions = {
    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'language',
    lookupLocalStorage: 'userLng',
    lookupSessionStorage: 'userLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 3 * 24 * 60, // 3days
    cookieDomain: process.env.REACT_APP_WEB_URL,

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' },
};

// init(sevice,ontions)
// languageDetector.init(languageDetectorOptions) // if no "service" , set localStorage = "i18nextLng"
languageDetector.init(null, languageDetectorOptions); //if "service" equa null, set localStorage follow "lookupLocalStorage"

// const i18nResources = {}
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(ChainedBackend)
    // .use(HttpBackend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(languageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lowerCaseLng: true,
        ns: ['common', 'navbar', 'sidebar', 'cookie-consent'],
        defaultNS: 'common', // can be a string or an array of namespaces
        load: 'languageOnly', //  'all' | 'currentOnly' | 'languageOnly'
        cleanCode: true, // Language will be lowercased EN --> en while leaving full locales like en-US
        whitelist: LANGUAGE_LIST,
        //lng: 'th', // Detect from languageDetector
        fallbackLng: 'th', // if cannot Detect from languageDetector, use fallbackLng
        backend: {
            backends: [
                LocalStorageBackend, // primary
                HttpBackend, // fallback
            ],
            backendOptions: [
                {
                    // prefix for stored languages
                    prefix: 'cache_lng_',
                    expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
                    defaultVersion: '', // Version applied to all languages, can be overriden using the option `versions`
                    versions: { en: 'v7.8', th: 'v7.8' },
                },
                {
                    loadPath: '/locales/{{lng}}/{{ns}}.json', // xhr load path for my own fallback
                },
            ],
        },
        // backend: {
        //     loadPath: '/locales/{{lng}}/{{ns}}.json' // xhr load path for my own fallback
        // },
        // debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })
    .then(() => {
        if (window) document.documentElement.setAttribute('lang', i18n.language.toLowerCase());
        // set timezone
        moment.locale(i18n.language.toLowerCase());
        moment.tz.setDefault('Asia/Bangkok');
    });

export default i18n;
