import styled from '@emotion/styled';

export const Wrapper = styled.div`
    label: developer-tools;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    color: #fff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #000000cc;
    z-index: 9999;
    transition: all 0.5s ease-in-out;

    hr {
        background-color: #fff;
    }
`;

export const NavTool = styled.div`
    label: tools-tool;

    display: block;
    width: 90%;
    padding-top: 12px;
    margin: auto;
`;
export const Tool = styled.div`
    label: tools-tool;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`;

export const Item = styled.div`
    label: tools-item;

    display: block;
    margin: 12px;
`;

export const ToolToggle = styled.div`
    label: tools-item;

    position: absolute;
    top: 100%;
    left: calc(50% - 38.5px);
    display: block;
    width: 75px;
    height: 25px;
    cursor: pointer;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #000000;

    &::before,
    &::after {
        content: '';
        position: absolute;
        display: block;
        width: 45%;
        height: 6px;
        border-radius: 6px;
        background-color: #777;
    }

    &::before {
        bottom: 40%;
        left: 10%;
        transform: rotate(16deg);
    }
    &::after {
        bottom: 40%;
        right: 10%;
        transform: rotate(165deg);
    }
`;
