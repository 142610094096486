import React, { useEffect } from 'react';
import { extendesClassname } from './utils';

export interface FormFieldComponent
    extends Omit<React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>, 'onSubmit'> {
    validated?: boolean;
    initialValues?: any;
    onSubmit?: (event: React.ChangeEvent<HTMLFormElement>, formData: any) => void;
    onHandleValid?: (valid: boolean) => void;
    onValidateChange?: (valid: boolean) => void;
}
/**
 * @version 1.0.2 parameter "onSubmit" return FormEventHandler
 * @version 1.0.1 create
 *
 */
function FormField({
    validated,
    initialValues,
    onSubmit,
    onHandleValid = () => null,
    onValidateChange = () => null,
    ...props
}: Readonly<FormFieldComponent>) {
    const formRefs = React.useRef<HTMLFormElement>(null!);
    const [isValid, setIsValid] = React.useState<boolean>(!!validated);

    const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        const form = event.currentTarget;

        event.preventDefault();

        if (!isValid) {
            if (form.checkValidity() === false) {
                formRefs.current.classList.add('exp-was-validated');
                // callback
                onHandleValid(true);

                event.stopPropagation();
            } else {
                let list: NodeListOf<HTMLInputElement> = form.querySelectorAll('input, textarea, select, date') ?? [];
                let formData = {};
                list.forEach((e, index: any) => {
                    if (e.type === 'radio') {
                        if (e.checked) {
                            formData[e?.name || `undefined${index}`] = e?.value ?? undefined;
                        }

                        return;
                    }

                    formData[e?.name || `undefined${index}`] = e?.value ?? undefined;
                });

                if (onSubmit) onSubmit(event, formData);
            }
        }
    };

    const onCheckValidate = (event: { currentTarget: any }) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            onValidateChange(false);
        } else {
            onValidateChange(true);
        }
    };

    React.useEffect(() => {
        setIsValid(!!validated);
    }, [validated]);

    useEffect(() => {
        const init = () => {
            if (formRefs.current && initialValues) {
                let list: NodeListOf<HTMLInputElement> = formRefs.current.querySelectorAll('input, textarea, select, date') ?? [];
                list.forEach((el) => {
                    if (initialValues[el?.name]) {
                        el.value = initialValues[el.name];
                    }
                });
            }
        };

        init();
    }, []);

    return (
        <form
            {...props}
            {...extendesClassname([props.className ?? ''])}
            noValidate
            ref={formRefs}
            onChange={onCheckValidate}
            onSubmit={handleSubmit}
        />
    );
}

export default FormField;
