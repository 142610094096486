export class LiftUpUtils {
    _currentGlobalLoader = null; //reference variable
    _awaitRegister = null; // popup list before register()

    static register(ref) {
        this._currentGlobalLoader = ref;

        if (this._awaitRegister) {
            this._awaitRegister.forEach((e) => this.open(e));
            this._awaitRegister = null; //clear
        }
    }

    static open(props) {
        if (this._currentGlobalLoader) {
            return this._currentGlobalLoader.open(props);
        } else {
            if (!this._awaitRegister) this._awaitRegister = [];

            this._awaitRegister.push(props);
        }
    }

    static updateProps({ ...props }, index) {
        if (this._currentGlobalLoader) {
            this._currentGlobalLoader.updateProps({ ...props }, index);
        }
    }

    static close(index) {
        if (this._currentGlobalLoader) {
            this._currentGlobalLoader.close(index, 'util');
        }
    }

    static closeAll() {
        if (this._currentGlobalLoader) {
            this._currentGlobalLoader.closeAll();
        }
    }
}
