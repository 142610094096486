import styled from '@emotion/styled';
import { Section } from 'components/layouts/page-layout';
import { breakpoint } from 'utils';

export const HomeAliveWrapper = styled(Section)`
    label: user-plugin;

    .profile-panel {
        position: relative;
        display: flex;
        align-items: center;
        width: calc(100% - (24px * 2));
        max-width: 600px;
        margin: 3rem auto;

        .profile-photo {
            border-radius: 50%;
            background-color: var(--text-normal-7);
            width: 100px;
            min-width: 100px;
            height: 100px;
            min-height: 100px;
            overflow: hidden;
            margin-right: 0.5rem;
            > img {
                object-fit: cover;
            }
        }
        .coverage-logo {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            width: 80px;
            margin-left: auto;
        }
    }

    .service-title {
        color: var(--primary-color);
        font-size: 1.3rem;
        text-align: center;
        font-weight: 500;
    }

    ${breakpoint('XS')} {
        /* padding: 18px; */
        /* margin-bottom: calc(1rem + 30px); */
        padding-bottom: 100px;

        .profile-panel {
            margin-top: 0;

            .profile-photo {
                width: 80px;
                min-width: 80px;
                height: 80px;
                min-height: 80px;
            }
        }
    }
`;

export const HomePluginWrapper = styled(Section)`
    label: user-plugin;

    .profile-panel {
        position: relative;
        display: flex;
        align-items: center;
        width: calc(100% - (24px * 2));
        max-width: 600px;
        margin: 3rem auto;

        .profile-photo {
            border-radius: 50%;
            background-color: var(--text-normal-7);
            width: 100px;
            min-width: 100px;
            height: 100px;
            min-height: 100px;
            overflow: hidden;
            margin-right: 0.5rem;
            > img {
                object-fit: cover;
            }

            .first-letter {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: #fff;
                font-size: 2.2rem;
                background-color: var(--primary-color);
            }
        }
        .coverage-logo {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            width: 80px;
            margin-left: auto;
        }
    }

    .service-title {
        color: var(--primary-color);
        font-size: 1.3rem;
        text-align: center;
        font-weight: 500;
    }
    .action-next {
        display: flex;
        justify-content: center;
    }
    ${breakpoint('XS')} {
        /* padding: 18px; */
        /* margin-bottom: calc(1rem + 30px); */
        padding-bottom: 100px;

        .profile-panel {
            margin-top: 0;

            .profile-photo {
                width: 80px;
                min-width: 80px;
                height: 80px;
                min-height: 80px;
            }
        }
    }
`;
