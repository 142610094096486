import CalendarJs from 'components/common/exp-calendarjs';
import { Col, Row } from 'components/common/grid-layout';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { TextTitle } from 'components/common/styled-components-common';
import { DatetimeBox } from 'components/elements/booking/datetime-box';
import TimeSlote from 'components/elements/booking/time-slote';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import type { SelectSlotData } from '../SelectLabAddress';
import { CalendarWrapper } from '../SelectLabAddress.styled';
import { SLOT_DATA } from '../mockData';
import { POST } from 'api';

export interface SelectDateTimeComponent {
    data: SelectSlotData;
    onSelect: (data: SelectSlotData) => void;
    slotsData: any;
}

function SelectDateTime({ slotsData, data, onSelect = () => null }: Readonly<SelectDateTimeComponent>) {
    const { t, i18n } = useTranslation(['claim', 'address']);

    //
    const [mode, setMode] = useState<'SLOT' | 'CALENDAR'>('SLOT');

    // const [slotsData, setSlotsData] = useState<{ date: string; timeList: string[] }[]>(SLOT_DATA);
    //
    const onChangeMode = (type: 'SLOT' | 'CALENDAR') => {
        setMode(type);
    };
    const onClickDateTime = (date: string, time: string | null) => {
        onSelect({
            date: date,
            time: time,
        });
    };
    const getDisabled = (date: Date) => {
        const disable = slotsData.some(
            (values) =>
                moment(values.date).format('DD/MM/YY') === moment(date).format('DD/MM/YY') &&
                moment(values.date).add(+1, 'day').startOf('day').isAfter(moment().startOf('day'))
        );

        return !disable;
    };

    const onChangeMonth = (date: moment.MomentInput) => {
        const timeThisMonth = slotsData.filter((el) => moment(el.date).format('MM/YYYY') === moment(date).format('MM/YYYY'));

        if (timeThisMonth && timeThisMonth.length > 0) {
            onClickDateTime(moment(timeThisMonth[0].date).format('YYYY-MM-DD'), timeThisMonth[0].timeList[0]);
        } else {
            onClickDateTime(moment(date).startOf('month').format('YYYY-MM-DD'), null);
        }
    };

    const findTime = (date: moment.MomentInput) => {
        const result = slotsData.find((el) => moment(el.date).format('DD/MM/YY') === moment(date).format('DD/MM/YY'));

        if (result?.timeList) {
            return result.timeList[0];
        }

        return null;
    };

    if (mode === 'SLOT') {
        return (
            <>
                <TextTitle>
                    <div>เลือกเวลานัดหมาย</div>
                    <button
                        style={{ fontSize: '1rem', backgroundColor: 'transparent', border: 'none' }}
                        onClick={() => onChangeMode('CALENDAR')}
                    >
                        แสดงปฏิทิน
                    </button>
                </TextTitle>
                <div>
                    <div className='booking-latest-date'>
                        <div className='text-primary fw-500 mb-2'>{t('claim:booking.earliest_available_dates')}</div>
                        <Row className='mb-3'>
                            {slotsData.slice(0, 3).map((values, index) => (
                                <Col key={index} tabIndex={index} xs={6} sm={4} className='pb-2'>
                                    <DatetimeBox
                                        data-test-id='dateBox'
                                        data-toggle={data.date === values.date}
                                        active={data.date === values.date}
                                        onClick={() => onClickDateTime(values.date, values.timeList[0])}
                                    >
                                        {moment(values.date).format('DD MMM YYYY ')}
                                    </DatetimeBox>
                                </Col>
                            ))}
                        </Row>
                        {data.time && <div className='text-primary fw-500  mb-2'>{t('claim:booking.earliest_available_times')}</div>}
                        <TimeSlote data={slotsData} dateSelect={data.date} timeSelect={data.time} onSelect={onClickDateTime} />
                    </div>
                </div>
            </>
        );
    }
    if (mode === 'CALENDAR') {
        return (
            <>
                <TextTitle>
                    <div>เลือกเวลานัดหมาย</div>
                    <button
                        style={{ fontSize: '1rem', backgroundColor: 'transparent', border: 'none' }}
                        onClick={() => onChangeMode('SLOT')}
                    >
                        เวลาใกล้ที่สุด
                    </button>
                </TextTitle>
                <div>
                    <CalendarWrapper className='calendar-content'>
                        <div className='calendar-calendar'>
                            {data.date && (
                                <div className='calendar-contrainer'>
                                    <div className='calendar-header'>
                                        <button
                                            disabled={!moment(data.date).isAfter(moment(), 'months')}
                                            onClick={() => onChangeMonth(moment(data.date).add(-1, 'month'))}
                                        >
                                            <IconConfigWithTheme pageName='calendar_component' iconName='reverse' />
                                        </button>
                                        <div>{moment(data.date).format(isMobile ? 'MMM YYYY' : 'MMMM YYYY')}</div>
                                        <button onClick={() => onChangeMonth(moment(data.date).add(1, 'month'))}>
                                            <IconConfigWithTheme pageName='calendar_component' iconName='next' />
                                        </button>
                                    </div>
                                    <div>
                                        <CalendarJs
                                            currentDate={new Date(data.date)}
                                            locale={i18n.language}
                                            dayOfWeek={isMobile ? 'short' : 'full'}
                                            disableDate={getDisabled}
                                            onSelectDate={(date) => onClickDateTime(moment(date).format('YYYY-MM-DD'), findTime(date))}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {data.time && <div className='text-primary fw-500 my-2'>{t('claim:booking.choose_time')}</div>}
                        <TimeSlote data={slotsData} dateSelect={data.date} timeSelect={data.time} onSelect={onClickDateTime} />
                    </CalendarWrapper>
                </div>
            </>
        );
    }

    return null;
}

export default SelectDateTime;
