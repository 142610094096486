import styled from '@emotion/styled';
import { breakpoint } from 'utils';

export const LiftUpWrapper = styled('div')<{ liftSize: string }>`
    label: exp-popup-slideup;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    z-index: var(--liftup-zindex);

    .exp-popup-slideup {
        &-overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: var(--N3);
        }

        &-container {
            position: relative;
            width: 96%;
            max-width: 750px;
            border-radius: 24px;
            overflow: hidden;
            transition: all 0.3s ease-in-out;
            background-color: #fff;
        }

        &-body {
            position: relative;
            width: 100%;
            height: 100%;
            max-height: 90vh;
            overflow-y: auto;
        }

        &-close-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 1.375rem;
            height: 1.375rem;
            font-size: 0;
            border: none;
            padding: 0;
            z-index: 10;
            background-color: transparent;

            &::after,
            &::before {
                content: '';
                position: absolute;
                top: calc(50% - 4px);
                display: block;
                width: 100%;
                height: 4px;
                border-radius: 4px;
                background-color: var(--gray-2);
                transform: rotate(45deg);
            }

            &::before {
                transform: rotate(-45deg);
            }
        }
    }

    &.show {
        opacity: 1;
    }

    ${(props) => breakpoint(props.liftSize)} {
        .exp-popup-slideup-container {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            bottom: -100%;
            width: 100%;
            max-width: unset;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .exp-popup-slideup-body {
            max-height: 100vh;
        }

        &.show .exp-popup-slideup-container {
            top: 5vh;
            bottom: 0;
        }
    }

    /* should not have in here */
    .liftup {
        &-content {
            position: relative;
            height: 100%;
        }
        &-header {
            position: relative;
            display: flex;
            align-items: center;
            min-height: 50px;
            padding: 0 1rem;
            background-color: #fff;

            &-left,
            &-right {
                position: relative;
                display: flex;
                align-items: center;
                margin-right: auto;
                z-index: 4;
            }
            &-right {
                margin-left: auto;
            }

            &.line-bottom {
                border-bottom: 1px solid var(--gray-2);
            }

            .title {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--primary-color);
                font-weight: 500;
            }

            .close-icon {
                position: relative;
                font-size: 1.4rem;
                color: var(--gray-2);
                z-index: 2;
                cursor: pointer;
            }
        }
        &-body {
            max-height: calc(80vh - 50px);
            padding: 1.5rem;

            ${(props) => breakpoint(props.liftSize)} {
                max-height: unset;
            }
        }

        &-floatingbar {
            height: 50px;
            min-height: unset;
            overflow: hidden;

            & + .liftup-body {
                height: 100%;

                ${(props) => breakpoint(props.liftSize)} {
                    max-height: calc(100% - 50px);
                }
            }
        }
    }

    ${(props) => breakpoint(props.liftSize)} {
        .liftup-body {
            max-height: unset;
        }
        .liftup-floatingbar + .liftup-body {
            max-height: calc(100% - 50px);
        }
    }
`;
