import React from 'react';
import styled from '@emotion/styled';
import { breakpoint } from 'utils';

interface ServiceDetailProps {
    primaryBgc?: string;
    secondBgc?: string;
    twoButton?: boolean;
}

const ServiceDetail = styled('div')<ServiceDetailProps>`
    label: landing-service-detail;

    position: relative;
    width: 100%;
    background-color: #f9f9fa;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${(props) => props.secondBgc || '#fbeae3'};
        z-index: 0;
    }

    .landing-service {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 95%;
        max-width: 1630px;
        margin: 0 auto;
        padding: 4rem 0;
        z-index: 1;

        ${breakpoint('XL')} {
            width: 100%;
            padding: 0;
        }

        .service-panel {
            width: 50%;
            color: #fff;
            padding: 3.556rem;
            .affiliated-pharmacy {
                display: none;
            }
            ${breakpoint('XL')} {
                width: 100%;
                margin: 0 auto;
                padding: 1.875rem 2.063rem;
                .affiliated-pharmacy {
                    display: block;
                    padding-bottom: 2rem;
                    margin-left: -2.063rem;
                    margin-right: -2.063rem;
                }
            }
        }

        .service-image {
            margin-top: auto;
            margin-left: auto;
            margin-right: auto;

            img[class='mobile'] {
                display: none;
            }

            ${breakpoint('XL')} {
                max-width: 750px;
                margin-bottom: -1rem;

                img {
                    &[class='desktop'] {
                        display: none;
                    }
                    &[class='mobile'] {
                        display: block;
                    }
                }
            }
        }
        .service-content {
            display: flex;
            flex-direction: column;
            padding-bottom: 1.444rem;
            border-top-left-radius: 1.25rem;
            border-bottom-left-radius: 1.25rem;
            text-align: center;
            background-color: ${(props) => props.primaryBgc ?? '#fd945b'};
            z-index: 2;
            transition: all 0.3s ease-in-out;

            .title {
                font-size: 1.111rem;
            }

            .sub-title {
                color: inherit;
                font-size: 2.667rem;
                font-weight: 500;
            }

            .detail {
                color: inherit;
                font-size: inherit;
                font-family: var(--font-detail2);
            }
            .sub-detail {
                color: inherit;
                font-size: 1.1rem;
                font-family: var(--font-detail2);
                margin-top: 1.2rem;
                padding: 0 15%;
                ${breakpoint('XL')} {
                    padding: 0;
                    margin-top: 0.5rem;
                }
            }
            ${breakpoint('XL')} {
                border-top-left-radius: 0;
                border-bottom-right-radius: 1.25rem;
                margin-bottom: -1.25rem;
                padding-bottom: 0;

                .title {
                    display: none;
                }

                .sub-title {
                    font-size: 1.5rem;
                }
            }

            ${breakpoint('SM')} {
                text-align: left;

                .detail span {
                    display: block;
                }
            }
        }

        .service-detail {
            display: flex;
            flex-direction: column;
            padding-top: calc(1.875rem + 1rem);
            border-top-right-radius: 1.25rem;
            border-bottom-right-radius: 1.25rem;
            background-color: #075150;
            background-image: url('/images/service-landing/service-background.webp');
            background-repeat: no-repeat;
            background-position: bottom right;
            background-size: 180px;

            .title {
                color: inherit;
                font-size: 1.556rem;
                font-weight: 400;
            }

            .detail {
                display: flex;
                padding: 1.167rem 0;
                font-size: 1.223rem;
                font-family: var(--font-detail2);
                margin-bottom: auto;

                h4 {
                    color: inherit;
                    font-size: inherit;
                }

                .icon {
                    min-width: calc(1.444rem + 1rem);
                    color: #8acbca;
                    font-size: 1.444rem;
                }

                & + [class='detail'] {
                    border-top: solid 1px #216968;
                }
            }

            ${breakpoint('XL')} {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                padding-bottom: 3.75rem;
                background-position: center right;

                .title {
                    font-size: 1.25rem;
                }

                .detail {
                    font-size: 1rem;
                    padding: 1.275rem 0;

                    .icon {
                        min-width: calc(1.125rem + 0.642rem);
                        font-size: 1.125rem;
                    }
                }
            }
        }
        .sevice-rate-panel {
            display: flex;
            flex-wrap: wrap;
            color: #707070;
            border-radius: 1rem;
            padding: 1.667rem 2.667rem 0 2.667rem;
            margin-top: 1rem;
            background-color: #ecf4f5;

            ${breakpoint('XL')} {
                padding: 2.625rem 1.375rem;
            }

            .sevice-rate {
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                .list {
                    display: flex;
                    width: 50%;
                    font-size: 0.778rem;
                    margin-bottom: 2rem;

                    .special-row {
                        width: 60%;
                    }
                    &.full-row {
                        width: 100%;
                    }
                    &-icon {
                        min-width: calc(2.111rem + 1rem);
                        height: 2.111rem;
                        color: #00acaa;
                        font-size: 2.111rem;
                        line-height: 1.5;
                    }

                    &-title {
                        color: #005150;
                        font-size: 0.889rem;
                        font-weight: 500;
                    }

                    &-detail {
                        color: inherit;
                        font-size: inherit;
                        font-weight: inherit;

                        span {
                            font-family: var(--font-detail2);

                            &:first-of-type {
                                font-size: 1.111rem;
                            }
                        }
                    }
                }

                ${breakpoint('XL')} {
                    .list {
                        font-size: 0.875rem;
                        margin-bottom: 1.625rem;

                        &-icon {
                            min-width: calc(1.75rem + 1rem);
                            height: 1.75rem;
                            font-size: 1.75rem;
                        }

                        &-title {
                            font-size: 1rem;
                        }

                        &-detail span:first-of-type {
                            font-size: 1rem;
                        }
                    }
                }

                ${breakpoint('MD')} {
                    .list,
                    .list:first-of-type {
                        width: 100%;
                    }
                }
            }

            .panel-footer {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 40px;
                margin-bottom: ${(props) => (props.twoButton ? '90px' : '-20px')};
                flex-wrap: ${(props) => (props.twoButton ? 'wrap' : 'none')};

                button {
                    width: 100%;
                    max-width: 335px;
                    padding: 0.778rem 0;
                    border-radius: 1.556rem;
                    margin: ${(props) => (props.twoButton ? '0.25rem 0' : 0)};
                }

                ${breakpoint('XL')} {
                    margin-bottom: 0;
                }
                ${breakpoint('XL')} {
                    height: ${(props) => (props.twoButton ? '80px' : '40px')};
                }
            }
        }
    }

    .consult-now {
        display: none;
        .custom-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            left: 3rem;
            bottom: 1.8rem;
            width: 65px;
            height: 65px;
            border-radius: 50%;
            background-color: ${(props) => props.secondBgc ?? '#ffe3d3'};
            color: ${(props) => props.primaryBgc ?? 'var(--btn-info-background-color)'};
            z-index: 5;

            i {
                font-size: 1.5rem;
            }
        }
        .custom-btn {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            padding: 2.625rem 3.438rem;
            z-index: 4;

            button {
                width: 100%;
                max-width: 350px;
                margin: ${(props) => (props.twoButton ? '0.25rem 0' : '0')};
            }
        }
        ${breakpoint('SM')} {
            display: block;
        }
    }
`;
export default ServiceDetail;
