import React from 'react';
import styled from '@emotion/styled';
import { breakpoint } from 'utils';

interface StyleProps {
    secondBgc?: string;
}

const ServiceMethod = styled.div<StyleProps>`
    label: landing-service-method;

    position: relative;
    padding: 4.444rem 1.25rem;
    background-color: #f9f9fa;

    ${breakpoint('XL')} {
        padding: 1.563rem 1.25rem;
    }

    ${breakpoint('XL', 'min')} {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 50%;
            background-color: ${(props) => props.secondBgc ?? '#fbeae3'};
            z-index: 0;
        }
    }

    .landing-panel {
        position: relative;
        width: 100%;
        max-width: 1630px;
        margin: auto;
        padding: 5rem 1.675rem;
        border-top-left-radius: 2.222rem;
        border-top-right-radius: 2.222rem;
        background-color: #fff;

        .title {
            color: #005150;
            font-size: 2.667rem;
            font-weight: 500;
            text-align: center;
            margin: auto auto 6.333rem auto;
        }

        ${breakpoint('XL')} {
            padding: 1.875rem 1rem;
            border-radius: 1.25rem;

            .title {
                font-size: 1.25rem;
                margin: auto auto 1.25rem auto;
            }
        }
    }

    .method {
        ${breakpoint('XL', 'min')} {
            .line-center::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                width: 100%;
                height: 4px;
                border-top: 2px dashed #cecece;
                z-index: -1;
            }
        }

        &-row {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1440px;
            margin-bottom: 1rem;
            margin: auto;
            z-index: 2;
        }

        &-col {
            display: flex;
            width: 30%;
            max-width: 300px;
            background-color: #fff;

            & + div {
                margin-left: auto;
            }
        }

        &-title {
            display: flex;
            color: #005150;
            font-size: 1.223rem;
            font-weight: 500;
            justify-content: center;
            align-items: center;
            margin: auto;

            h3 {
                color: inherit;
                font-size: inherit;
                margin: inherit;
            }

            .circle {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1.334rem;
                height: 1.334rem;
                color: #fff;
                font-size: 1rem;
                font-weight: initial;
                margin-right: 0.5rem;
                border-radius: 50%;
                background-color: #1aacaa;
            }
        }

        &-image {
            position: relative;
            max-width: 180px;
            margin: auto;
            padding: 1rem;
        }

        &-detail {
            position: relative;
            margin-top: 1rem;
            padding: 0 0.5rem;

            .method-title {
                display: none;
            }
        }

        ${breakpoint('XL')} {
            &-row {
                max-width: 380px;
            }

            &-col {
                display: none;
                width: 100%;
                max-width: unset;
                margin: 0;

                &.active {
                    display: flex;
                }
            }

            &-title {
                color: #a0a0a0;
                margin: 0.625rem 0;

                .circle {
                    background-color: #a0a0a0;
                }

                &.active {
                    color: #000;

                    .circle {
                        background-color: #00acaa;
                    }
                }
            }

            &-detail {
                .method-title {
                    display: flex;
                    justify-content: flex-start;
                    color: #000;
                    font-size: 1.25rem;
                    margin: 0;
                }
            }

            .mark-1 {
                flex-direction: row-reverse;
                flex-wrap: nowrap;
            }

            .mark-2 {
                display: block;

                .method-col {
                    display: flex;
                }
            }
        }

        ${breakpoint('SM')} {
            .mark-3 {
                max-width: 124px;
            }
        }
    }
`;

export default ServiceMethod;
