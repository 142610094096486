import styled from '@emotion/styled';
import { breakpoint } from 'utils';

export const CustomPanel = styled('div')`
    padding: 0 1.5rem;
    ${breakpoint('XS')} {
        padding: 0 1rem;
    }
`;
export const CardAddress = styled('div')`
    width: 100%;
    min-height: 60px;
    border-radius: 10px;
    border: solid 2px var(--text-normal-4);
    padding: 12px 24px;
    margin: 1rem 0;
    font-size: 16px;
    background-color: var(--white);

    &.card-status-selected {
        border-color: var(--second-color);

        .selected-icon {
            color: var(--second-color);
        }
    }
    &.card-status-error {
        border-color: var(--error-color);
    }

    .card-select-body > div {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
    }

    .card-select-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .edit-button {
            border: none;
            background-color: transparent;
        }
    }
`;

export const MapsWrapper = styled('div')<{ size: string }>`
    label: map;

    position: relative;
    height: 400px;
    margin-bottom: 1rem;
    border: 1px solid #aaa;

    .-search-place-auto {
        position: absolute;
        top: 6px;
        left: 6px;
        right: ${(props) => (props.size === 'maximize' ? '25%' : '6px')};
        z-index: 1;
        ${breakpoint('XS')} {
            right: 6px;
        }
    }
    .gm-style > div:first-of-type::after {
        content: '';
        position: absolute;
        top: calc(50% - 50px);
        left: calc(50% - 25px);
        display: block;
        width: 50px;
        height: 50px;
        background-image: url('/images/maps/marker.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 9;
    }

    .validate {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
    }
`;

export const CalendarWrapper = styled.div`
    .calendar-contrainer {
        width: 100%;
        max-width: 800px;
    }

    .calendar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;

        button {
            font-size: 2rem;
            color: var(--second-color);
            border-radius: 50%;
            padding: 0;
            line-height: 0;
            border: none;

            &:disabled {
                color: var(--text-normal-2);
            }
        }
    }

    .calendar-table {
        width: 100%;
        text-align: center;
        table-layout: fixed;
        border-spacing: 0;

        .calendar-column-header {
            font-weight: 400;
            padding-bottom: 12px;
        }

        .calendar-tbody .calendar-cell {
            color: var(--text-normal-1);
            padding: 0;
            padding-bottom: 14px;
        }

        .calendar-date {
            width: 26px;
            margin: 0 auto;
            border-radius: 4px;
            cursor: pointer;
        }

        .calendar-cell.calendar-selected-day .calendar-date {
            color: #ffffff;
            background-color: #0071f0;
            transition: color 0.3s;
        }
        .calendar-cell.calendar-disabled-btn-day.calendar-selected-day .calendar-date {
            background-color: #70707080;
            cursor: not-allowed;
        }
        .calendar-cell.calendar-disabled-btn-day:not(.calendar-selected-day) .calendar-date {
            width: 100%;
            color: var(--text-normal-4);
            border-radius: 0;
            background-color: #70707010;
            cursor: not-allowed;
        }
    }
`;

export const ButtonNewAddress = styled('button')`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    width: 100%;
    color: var(--second-color);
    text-align: center;
    border-radius: 10px;
    border: dashed 1px var(--second-color);
    background-color: var(--address-add-background-color);
`;
