import React, { useEffect, useRef, useState } from 'react';
import { LiftUpUtils } from './lift-up-utils';

/**
 * @version 1.0.3 update container width and close icon
 * @version 1.0.2 add parameter "maxWidth"
 * @version 1.0.1 create
 *
 * @default isClickOutSideClose = true
 *
 * @param {{
 * show?: boolean,
 * showClose?: boolean,
 * isClickOutSideClose?: boolean,
 * onlyLiftup?: boolean,
 * zIndex?: string | number | 999,
 * liftSize?: 'XS' | 'SM' | 'MD' | 'LG' | 'XL' | 'XXL' | 'XXXL',
 * backgroundColor?: string,
 * maxWidth?: string,
 * className?: string,
 * children?: React.ReactNode,
 * onClose:()=> void
 * }} props
 * @returns
 */
function SlideUp({ show, children, onClose = () => null, ...props }) {
    const [isShow, setIsShow] = useState(false);
    const liftRefs = useRef();

    useEffect(() => {
        if (show) {
            liftRefs.current = LiftUpUtils.open({ show, onClose, children, ...props });
            setIsShow(true);
        }

        if (!show && isShow && liftRefs.current?.id) {
            LiftUpUtils.close(liftRefs.current?.id);
            setIsShow(false);
        }
    }, [show]);

    useEffect(() => {
        if (isShow && liftRefs.current?.id) {
            LiftUpUtils.updateProps({ show, onClose, id: liftRefs.current?.id, children, ...props }, liftRefs.current?.id);
            // LiftUpUtils.updateProps({ show, onClose, children, ...props });
        }
    }, [isShow, children, props, liftRefs.current]);

    return <></>;
}

export default SlideUp;
