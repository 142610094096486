import React from 'react';
import FormGroup from '../form-group';
import FieldRadio from './field-radio';
import { extendesClassname } from '../utils';
import FormInvalidFeedback from '../form-invalid-feedback';
import FormRecommend from '../form-recommend';
import FormLabel from '../form-label';

/**
 *
 * @param {{
 * title: String | Element,
 * radioListGroup: Array<{
 *      label: String | Element,
 *      value: String,
 *      xs: xs,
 *      sm?: xs,
 *      md?: xs,
 *      lg?: xs,
 *      xl?: xs,
 *      xxl?: xs,
 *      xxxl?: xs,
 * }>,
 * className: String | Element,
 * classNameLabel: String,
 * classNameField: String,
 * errorMsg: String | Element,
 * recommendMsg: String | Element,
 * isInvalid: Boolean,
 * titleTextSmall: Boolean,
 * xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto' | undefined,
 * sm?: xs,
 * md?: xs,
 * lg?: xs,
 * xl?: xs,
 * xxl?: xs,
 * xxxl?: xs,
 * } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} peops
 * @returns
 */
function FieldRadioGroup({
    title = '',
    value = '',
    radioListGroup = [],
    className = '',
    classNameLabel = '',
    classNameField = '',
    errorMsg = '',
    recommendMsg = '',
    isInvalid = false,
    titleTextSmall = true,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    xxxl,
    children,
    onChange = () => {},
    ...props
}) {
    return (
        <FormGroup xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} xxxl={xxxl} className={classNameField}>
            {title && (
                <FormLabel textSmall={titleTextSmall} className={classNameLabel} required={props.required}>
                    {title}
                </FormLabel>
            )}
            <div className='exp-form-row'>
                {radioListGroup.map((item, index) => (
                    <FieldRadio
                        {...props}
                        {...extendesClassname(['exp-form-check-input', titleTextSmall && 'exp-form-label-small', className])}
                        key={index}
                        type='radio'
                        id={item.id ? item.id : `${props.name}-nth-${index}`}
                        label={item.label}
                        value={item.value || ''}
                        selectValue={value}
                        checked={value && value === item.value}
                        xs={item.xs}
                        sm={item.sm}
                        md={item.md}
                        lg={item.lg}
                        xl={item.xl}
                        xxl={item.xxl}
                        xxxl={item.xxxl}
                        isInvalid={isInvalid}
                        onChange={(e) => {
                            console.log('e:', e);
                            onChange(e);
                        }}
                    />
                ))}
                {children}
            </div>
            {errorMsg && <FormInvalidFeedback isInvalid={isInvalid}>{errorMsg}</FormInvalidFeedback>}
            {recommendMsg && <FormRecommend>{recommendMsg}</FormRecommend>}
        </FormGroup>
    );
}

export default FieldRadioGroup;
