import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/common/button';
import ServiceDetail from 'modules/page/landing/ServiceDetail';
import { cryptojs } from 'utils';
import moment from 'moment';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import useAuth from 'hooks/useAuth';
import type { DataEncodeURLProps } from 'types';
import ServiceSymstom from 'modules/page/landing/ServiceSymstom';
import ServiceMethod from 'modules/page/landing/ServiceMethod';
import ServicePharmacyNearby from 'modules/page/landing/ServicePharmacyNearby';
import ServiceRating from 'modules/page/landing/ServiceRating';
import ServiceQeustionAndAnswer, { QeustionBox } from 'modules/page/landing/ServiceQnA';
import ServiceRelate from 'modules/page/landing/ServiceRelate';
import LandingLogin from 'modules/page/landing/LandingLogin';

function ATKServiceLanding() {
    const navigate = useNavigate();
    const { auth } = useAuth();
    const timer = useRef<NodeJS.Timeout>();
    const [showMethod, setShowMethod] = useState<number>(1);

    const onNextPage = () => {
        let path = process.env.REACT_APP_LIFF_REDIRECT_DEFAULT ?? '';
        let dataForEncode: DataEncodeURLProps = {
            path: '/telepharmacy/claim',
            params: window.location.search || '?voucher=ATK1&fromService=true',
            data: {
                authorization: null,
                userId: null,
                autoLogin: 0,
            },
        };
        if (window.location.origin.includes('fastcare.co')) {
            path = process.env.REACT_APP_LIFF_REDIRECT_FASTCARE ?? '';
            dataForEncode = {
                ...dataForEncode,
                params: window.location.search || '?voucher=fastcare01&fromService=true',
                data: {
                    authorization: null,
                    userId: null,
                    autoLogin: 0,
                    partner: '18',
                },
            };
        }
        const encode = cryptojs.encode(dataForEncode);
        if (auth.isAuth) {
            return navigate(dataForEncode.path + dataForEncode.params);
        }
        return window.location.assign(path + '?to=' + encode);
    };

    const onChangeMethod = (method: number) => {
        let _method = method;

        clearInterval(timer.current);
        setShowMethod(_method);

        timer.current = setInterval(() => {
            _method++;

            if (_method < 4) {
                setShowMethod(_method);
            } else {
                setShowMethod(1);
                _method = 1;
            }
        }, 5000);
    };

    const onContinueService = () => {
        let path = process.env.REACT_APP_LIFF_REDIRECT_DEFAULT;
        let dataForEncode: DataEncodeURLProps = {
            path: '/telepharmacy/claim',
            params: window.location.search || '?voucher=TLP&fromService=true',
            data: {
                authorization: null,
                userId: null,
                autoLogin: 0,
            },
        };
        if (window.location.origin.includes('fastcare.co')) {
            path = process.env.REACT_APP_LIFF_REDIRECT_FASTCARE;
            dataForEncode = {
                ...dataForEncode,
                params: window.location.search || '?voucher=fastcare01&fromService=true',
                data: {
                    authorization: null,
                    userId: null,
                    autoLogin: 0,
                    partner: '18',
                },
            };
        }
        const encode = cryptojs.encode(dataForEncode);
        if (auth.isAuth) {
            return dataForEncode.path + dataForEncode.params;
        }
        return path + '?to=' + encode;
    };

    const params = useMemo(() => {
        const origin = window.location.origin;
        const voucher = origin.includes('fastcare.co') ? 'fastcare01' : 'ATK1';
        return `?voucher=${voucher}`;
    }, [window.location.origin]);

    useEffect(() => {
        onChangeMethod(1);

        return () => clearInterval(timer.current);
    }, []);

    return (
        <>
            <ServiceDetail>
                <div className='landing-service'>
                    <div className='service-panel service-content pb-0'>
                        <div className='title'>บริการ</div>
                        <h1 className='sub-title'>ปรึกษาเภสัชกรและซื้อ ATK</h1>
                        <h4 className='detail'>
                            <div>
                                <span>ซื้อชุดตรวจ ATK ที่ได้มาตรฐาน</span>
                                <span>(และยาตามอาการโควิด) </span>
                            </div>
                            <div>
                                <span>วิดีโอคอล รับคำแนะนำจากเภสัชกร</span>
                                <span>พร้อมบริการจัดส่งถึงบ้าน</span>
                            </div>
                        </h4>
                        <div className='service-image mb-0'>
                            <img src='/images/service-landing/service-atk.webp' className='desktop' alt='desktop' />
                            <img src='/images/service-landing/service-atk.webp' className='mobile' alt='mobile' />
                        </div>
                    </div>
                    <div className='service-panel service-detail'>
                        <h3 className='title'>รายละเอียดบริการ</h3>
                        <div className='detail'>
                            <div className='icon'>
                                <IconConfigWithTheme pageName='landing1_atk' iconName='sevice_detail' />
                            </div>
                            <h4>วิดีโอคอล รับคำแนะนำจากเภสัชกร พร้อมบริการจัดส่งถึงบ้าน</h4>
                        </div>
                        <div className='detail'>
                            <div className='icon'>
                                <IconConfigWithTheme pageName='landing1_atk' iconName='dalivery_time' />
                            </div>
                            <h4>ระยะเวลาจัดส่งประมาณ 2-3 ชม.</h4>
                        </div>
                        <h3 className='title'>อัตราค่าบริการ</h3>
                        <div className='sevice-rate-panel'>
                            <div className='sevice-rate'>
                                <div className='list special-row'>
                                    <div className='list-icon'>
                                        <IconConfigWithTheme pageName='landing1_atk' iconName='sevice_rate' />
                                    </div>
                                    <div className='list-content'>
                                        <h3 className='list-title'>ค่า ATK และ ยา (ถ้ามี)</h3>
                                        <h4 className='list-detail'>
                                            <span>ราคาตามหน้าร้านยา</span>
                                            <div>(ถ้าไม่มีการซื้อยา อาจมีค่าปรึกษาเภสัชกรรม 50 บาท)</div>
                                        </h4>
                                    </div>
                                </div>
                                <div className='list'>
                                    <div className='list-icon'>
                                        <IconConfigWithTheme pageName='landing1_atk' iconName='dalivery_rate' />
                                    </div>
                                    <div className='list-content'>
                                        <h3 className='list-title'>ค่าจัดส่ง</h3>
                                        <h4 className='list-detail'>
                                            <div className='line-nomal'>เริ่มต้นที่ 57 บาท</div>
                                            <div>(ฟรีค่าจัดส่ง ตั้งแต่วันนี้ถึง {moment('2565-09-30').format('D MMM YYYY')})</div>
                                        </h4>
                                    </div>
                                </div>
                                <div className='list'>
                                    <div className='list-icon'>
                                        <IconConfigWithTheme pageName='landing1_atk' iconName='management_rate' />
                                    </div>
                                    <div className='list-content'>
                                        <h3 className='list-title'>ค่าธรรมเนียมการจัดการ</h3>
                                        <h4 className='list-detail'>
                                            <div className='line-through'>55 บาท </div>
                                            <div>(*ฟรีค่าธรรมเนียมในเดือนเมษายน-ธันวาคม 2565)</div>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className='panel-footer'>
                                <Button variant='info' onClick={onNextPage}>
                                    ปรึกษาเภสัชกรตอนนี้
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='consult-now'>
                    <div className='custom-btn'>
                        <Button variant='info' onClick={onNextPage}>
                            ปรึกษาเภสัชกรตอนนี้
                        </Button>
                    </div>
                </div>
            </ServiceDetail>
            <ServiceSymstom>
                <div className='landing-panel'>
                    <div className='landing-icon'>
                        <img src='/images/service-landing/pharmcare-short-logo.svg' alt='pharmcare-icon' />
                    </div>
                    <h2>
                        <div className='title'>อาการเบื้องต้น</div>
                        <div className='sub-title'>ที่เภสัชกรของเราให้คำปรึกษา</div>
                    </h2>
                    <div className='landing-content'>
                        <div className='landing-image'>
                            <img src='/images/service-landing/symptoms-atk.webp' alt='symptoms' />
                        </div>
                        <div className='symptoms'>
                            <div className='symptoms-about'>อาการเกี่ยวกับ...</div>
                            <ul className='symptoms-list mark-responsive'>
                                <li>
                                    <h4>การใช้ที่ตรวจ ATK</h4>
                                </li>
                                <li>
                                    <h4>การปฏิบัติตัวเมื่อมีความเสี่ยง</h4>
                                </li>
                                <li>
                                    <h4>อาการเบื้องต้น</h4>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ServiceSymstom>
            <ServiceMethod>
                <div className='landing-panel'>
                    <h3 className='title'>ขั้นตอนในการรับบริการ</h3>
                    <div className='method'>
                        <div className='method-row mark-1'>
                            <div className='method-row mark-2'>
                                <div className='method-col'>
                                    <div
                                        className={showMethod === 1 ? 'method-title active' : 'method-title'}
                                        onClick={() => onChangeMethod(1)}
                                    >
                                        <div className='circle'>1</div>
                                        <h3>ปรึกษาเภสัชกร</h3>
                                    </div>
                                </div>
                                <div className='method-col'>
                                    <div
                                        className={showMethod === 2 ? 'method-title active' : 'method-title'}
                                        onClick={() => onChangeMethod(2)}
                                    >
                                        <div className='circle'>2</div>
                                        <h3>จ่ายยาตามอาการ</h3>
                                    </div>
                                </div>
                                <div className='method-col'>
                                    <div
                                        className={showMethod === 3 ? 'method-title active' : 'method-title'}
                                        onClick={() => onChangeMethod(3)}
                                    >
                                        <div className='circle'>3</div>
                                        <h3>รอรับยา</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='method-row mark-3 line-center'>
                                <div className={showMethod === 1 ? 'method-col active' : 'method-col'}>
                                    <div className='method-image'>
                                        <img src='/images/service-landing/method-1.webp' alt='method-1' />
                                    </div>
                                </div>
                                <div className={showMethod === 2 ? 'method-col active' : 'method-col'}>
                                    <div className='method-image'>
                                        <img src='/images/service-landing/method-2.webp' alt='method-2' />
                                    </div>
                                </div>
                                <div className={showMethod === 3 ? 'method-col active' : 'method-col'}>
                                    <div className='method-image'>
                                        <img src='/images/service-landing/method-3.webp' alt='method-3' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='method-row'>
                            <div className={showMethod === 1 ? 'method-col active' : 'method-col'}>
                                <div className='method-detail'>
                                    <div className='method-title'>
                                        <div>1. ปรึกษาเภสัชกร</div>
                                    </div>
                                    <p>กรอกข้อมูลสุขภาพ อาการของคุณ และเริ่มต้นสนทนากับเภสัชกร ภายใน 5 นาที</p>
                                </div>
                            </div>
                            <div className={showMethod === 2 ? 'method-col active' : 'method-col'}>
                                <div className='method-detail'>
                                    <div className='method-title'>
                                        <div>2. จ่ายยาตามอาการ</div>
                                    </div>
                                    <p>หากต้องการรับยา เภสัชกรจะจัดยาตามอาการเบื้องต้นให้คุณ</p>
                                </div>
                            </div>
                            <div className={showMethod === 3 ? 'method-col active' : 'method-col'}>
                                <div className='method-detail'>
                                    <div className='method-title'>
                                        <div>3. รอรับยา</div>
                                    </div>
                                    <p>ยืนยันรายการ ที่อยู่จัดส่ง เพื่อรอรับยาพร้อมติดตามสถานะแบบเรียลไทม์</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ServiceMethod>
            <ServicePharmacyNearby serviceLink={onContinueService()} />
            <ServiceRating>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ตอนแรกนอยด์มาก ทำอะไรไม่ถูก ขอชมน้องเภสัชกรน่ารัก ให้คำแนะนำการปฏิบัติตัวดีมาก หายเครียดไปเยอะค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_atk' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>พราว</div>
                            <div className='rating-date'>27 May 2020</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            หลังจากวางสายกับเภสัชกร รออยู่บ้านไม่นาน ชุดตรวจพร้อมยาก็มาถึงหน้าบ้าน สะดวกมากครับ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_atk' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>ทิม</div>
                            <div className='rating-date'>27 May 2020</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            ได้รับของเรียบร้อย และรู้สึกมั่นใจ เพราะยี่ห้อชุดตรวจที่ได้รับ มีการรับรองจากทางการค่ะ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_atk' iconName='star' />

                            <span>4</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>ธารา</div>
                            <div className='rating-date'>27 May 2020</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>สามารถปรึกษาอาการเกี่ยวกับโควิดได้ด้วย ประทับใจค่ะ</div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_atk' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>ป่าน</div>
                            <div className='rating-date'>27 May 2020</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>
                            นอกจากแนะนำการใช้ชุดตรวจ ATK แล้วยังแนะนำให้สังเกตอาการตนเองที่บ้านเบื้องต้นด้วย เภสัชกรใส่ใจดีมากครับ
                        </div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_atk' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>เจเจ</div>
                            <div className='rating-date'>27 May 2020</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>ได้รับชุดตรวจ ATK และยาสภาพเรียบร้อย สะดวกดีค่ะ</div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_atk' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>มิว</div>
                            <div className='rating-date'>27 May 2020</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='rating-box'>
                        <div className='rating-comment'>ภสัชกรน่ารัก เป็นกันเอง ตอบคำถามเราได้ดีมาก สบายใจขึ้นเยอะค่ะ</div>
                        <div className='rating-score'>
                            <IconConfigWithTheme pageName='landing5_atk' iconName='star' />

                            <span>5</span>
                        </div>
                        <div className='rating-footer'>
                            <div className='rating-name'>ปาย</div>
                            <div className='rating-date'>27 May 2020</div>
                        </div>
                    </div>
                </div>
            </ServiceRating>
            <ServiceQeustionAndAnswer>
                <div className='landing-panel'>
                    <h2 className='title'>คำถามที่พบบ่อย</h2>
                    <div className='landing-content'>
                        <div className='landing-image'>
                            <img src='/images/service-landing/question.webp' alt='question' />
                        </div>
                        <div className='question-n-answer'>
                            <QeustionBox question={'1. มีบริการในจังหวัดใดบ้าง'}>บริการเราสามารถใช้ได้ทุกจังหวัดทั่วประเทศไทย</QeustionBox>
                            <QeustionBox question={'2. ค่าจัดส่งคิดอย่างไร'}>
                                <p>ฟรีค่าจัดส่ง ตั้งแต่วันนี้ถึง {moment('2565-09-30').format('D MMM YYYY')}</p>
                                {/* <>
                                    <p>
                                        เขตกทม.และปริมณฑล คิดค่าบริการ 57 บาท สำหรับ 1 กิโลเมตรแรก และเพิ่ม 7 บาท สำหรับทุกๆ 1 กิโลเมตรถัดไป
                                        ซึ่งจะได้รับยาภายใน 3 ชั่วโมง
                                    </p>
                                    <div>สำหรับต่างจังหวัด คิดค่าจัดส่ง 80 บาท และจะได้รับยาภายใน 1-3 วัน</div>
                                </> */}
                            </QeustionBox>
                            <QeustionBox question={'3. ใช้เวลาจัดส่ง นานเท่าไหร่'}>
                                <p>สำหรับบริการจัดส่งยาในเขตกทมและปริมณฑล คนไข้จะได้รับยาภายใน 3 ชม</p>
                                <div>
                                    ยกเว้นรหัสไปรษณีย์ (10280, 10550, 10560, 10570, 11150, 12110, 12170, 74120, 74130) ใช้เวลาจัดส่ง 1-3 วัน
                                </div>
                                <div>สำหรับบริการจัดส่งยาในต่างจังหวัด คนไข้จะได้รับยาภายใน 1-3 วัน</div>
                            </QeustionBox>
                            <QeustionBox question={'4. ช่วงเวลาเปิดให้บริการ คือช่วงใด'}>10:00-20:00 ทุกวัน</QeustionBox>
                            <QeustionBox question={'5. ราคา ATK เท่าไหร่'}>
                                ราคา ATK เป็นราคาเดียวกับที่ลูกค้ามาซื้อหน้าร้าน ของแต่ละร้าน (ไม่บวกเพิ่ม)
                            </QeustionBox>
                            <QeustionBox question={'6. ถ้าต้องการซื้อ ATK เฉยๆ จำเป็นต้องปรึกษากับเภสัชกรไหม'}>
                                ระบบ PharmCare จะมีการปรึกษาเภสัชกรก่อนซื้อสินค้าทุกครั้งเพื่อให้เภสัชกรแนะนำวิธีการใช้ ATK หรือ
                                ยาให้กับลูกค้า
                            </QeustionBox>
                            <QeustionBox question={'7. ชำระเงินผ่านช่องทางใดได้บ้าง'}>
                                สามารถชำระด้วยบัตรเครดิต/เดบิท หรือ โอนเงินผ่านธนาคารโดยใช้ Promptpay
                            </QeustionBox>
                            <QeustionBox question={'8. มีบริการเก็บเงินปลายทางหรือไม่'}>ไม่มีค่ะ</QeustionBox>
                        </div>
                    </div>
                </div>
            </ServiceQeustionAndAnswer>
            <ServiceRelate serviceList={['telemed', 'storeNearby']} />
            <LandingLogin onNextPath='/telepharmacy/claim' params={params} />
        </>
    );
}
export default ATKServiceLanding;
