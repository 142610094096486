import React from 'react';
import styled from '@emotion/styled';
import { breakpoint } from 'utils';

interface ServiceSymstomProps {
    secondBgc?: string;
    primaryBgc?: string;
    imageBgc?: string;
}

const ServiceSymstom = styled.div<ServiceSymstomProps>`
    label: landing-service-symptom;

    background-color: #fff;
    position: relative;
    width: 100%;
    padding: 4.44rem 1.334rem;
    background-color: ${(props) => props.secondBgc || '#fbeae3'};
    transition: all 0.3s ease-in-out;

    ${breakpoint('XL')} {
        padding: 3.75rem 1.25rem 1.563rem;
        background-color: #f9f9fa;
    }

    .landing {
        &-icon {
            display: none;
            width: 2.125rem;
            height: 2.125rem;
            margin: 0.75rem auto;

            ${breakpoint('XL')} {
                display: block;
            }
        }

        &-panel {
            position: relative;
            width: 100%;
            max-width: 1212px;
            color: #005150;
            text-align: center;
            margin: auto;
            border-radius: 1.25rem;

            h2 {
                color: inherit;
                font-size: inherit;
            }

            .title {
                font-size: 3.444rem;
                font-weight: 500;
            }

            .sub-title {
                font-size: 2.667rem;
                font-weight: 500;
            }

            ${breakpoint('XL')} {
                padding-top: 1.875rem;
                background-color: ${(props) => props.primaryBgc || '#ffe3d3'};

                .title {
                    font-size: 1.625rem;
                }
                .sub-title {
                    font-size: 1.375rem;
                }
            }
        }

        &-image {
            width: 100%;
            max-width: 635px;
            margin: auto;

            ${breakpoint('XL')} {
                max-width: 450px;
            }
        }

        &-content {
            margin-top: 3rem;
            padding: 4rem 1.334rem;
            border-radius: 2.222rem;
            background-color: ${(props) => props.primaryBgc || '#ffe3d3'};
            background-image: ${(props) => `url('/images/service-landing/symptoms-background-${props.imageBgc}.webp')`};
            background-repeat: no-repeat;
            background-position: -80px calc(100% + 80px);
            background-size: 300px;

            .symptoms {
                width: 100%;
                max-width: 635px;
                margin: 1.334rem auto;
                text-align: left;

                &-about {
                    font-size: 1.334rem;
                    font-weight: 500;
                }

                &-list {
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 1.667rem;
                    font-family: var(--font-detail2);
                    padding-left: 1.125rem;

                    li {
                        width: 50%;

                        h4 {
                            color: inherit;
                            font-size: inherit;
                        }
                    }

                    ${breakpoint('SM')} {
                        &.mark-responsive li {
                            width: 100%;
                        }
                    }
                }
            }

            ${breakpoint('XL')} {
                margin-top: 0;
                padding: 1.25rem 2.25rem;
                background-position: -60px calc(100% + 60px);
                background-size: 220px;

                .symptoms {
                    &-about {
                        font-size: 1.125rem;
                    }

                    &-list {
                        font-size: 1rem;
                        /* padding-left: 1rem; */
                    }
                }
            }
        }
    }
`;

export default ServiceSymstom;
