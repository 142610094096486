import { EXPCollapse } from 'components/common/exp-collapse';
import React, { useState } from 'react';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { ServiceQeustionStyle } from './Landing.styled';

function ServiceQeustionAndAnswer({ children }: Readonly<{ children: React.ReactNode }>) {
    return <ServiceQeustionStyle>{children}</ServiceQeustionStyle>;
}

function QeustionBox({ question, children }: Readonly<{ question: string; children: React.ReactNode }>) {
    const [toggle, setToggle] = useState(false);

    return (
        <div className='list'>
            <div className='question' onClick={() => setToggle(!toggle)}>
                <h3>{question}</h3>
                <IconConfigWithTheme pageName='question_box' iconName='show_answer' className={`${toggle ? '' : 'fa-rotate-180'}`} />
            </div>
            <EXPCollapse show={toggle}>
                <div className='answer'>{children}</div>
            </EXPCollapse>
        </div>
    );
}

export default ServiceQeustionAndAnswer;
export { QeustionBox };
