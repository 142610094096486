import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { ServiceRelateStyle } from './Landing.styled';

// Define types for the service configuration
interface ServiceConfig {
    desktop: string;
    mobile: string;
    path: string;
    alt: string;
}

const serviceListConfig: Record<string, ServiceConfig> = {
    telepharmacy: {
        desktop: '/images/service-landing/service-card/desktop/telepharmacy-desktop.webp',
        mobile: '/images/service-landing/service-card/mobile/telepharmacy-mobile.webp',
        path: '/service/telepharmacy',
        alt: '',
    },
    telepharmacyHumatrix: {
        desktop: '/images/service-landing/service-card/desktop/telepharmacy-desktop.webp',
        mobile: '/images/service-landing/service-card/mobile/telepharmacy-mobile.webp',
        path: '/service/telepharmacy?voucher=TLP&billing=human01',
        alt: '',
    },
    consult: {
        desktop: '/images/service-landing/service-card/desktop/consult-desktop.webp',
        mobile: '/images/service-landing/service-card/mobile/consult-mobile.webp',
        path: '',
        alt: '',
    },
    risk: {
        desktop: '/images/service-landing/service-card/desktop/risk-desktop.webp',
        mobile: '/images/service-landing/service-card/mobile/risk-mobile.webp',
        path: '',
        alt: '',
    },
    telemed: {
        desktop: '/images/service-landing/service-card/desktop/telemed-desktop.webp',
        mobile: '/images/service-landing/service-card/mobile/telemed-mobile.webp',
        path: '/service/telemed',
        alt: 'ปรึกษาแพทย์ออนไลน์',
    },
    atk: {
        desktop: '/images/service-landing/service-card/desktop/atk-desktop.webp',
        mobile: '/images/service-landing/service-card/mobile/atk-mobile.webp',
        path: '',
        alt: '',
    },
    health: {
        desktop: '/images/service-landing/service-card/desktop/health-desktop.webp',
        mobile: '/images/service-landing/service-card/mobile/health-mobile.webp',
        path: '',
        alt: '',
    },
    nutritionist: {
        desktop: '/images/service-landing/service-card/desktop/nutritionist-desktop.webp',
        mobile: '/images/service-landing/service-card/mobile/nutritionist-mobile.webp',
        path: '',
        alt: '',
    },
    physiotherapy: {
        desktop: '/images/service-landing/service-card/desktop/physiotherapy-desktop.webp',
        mobile: '/images/service-landing/service-card/mobile/physiotherapy-mobile.webp',
        path: '',
        alt: '',
    },
    storeNearby: {
        desktop: '/images/service-landing/service-card/desktop/store-desktop.webp',
        mobile: '/images/service-landing/service-card/mobile/store-mobile.webp',
        path: '/store/nearby?filter=true&type=WALK_IN*GATE_WAY*VIDEO_CALL',
        alt: 'ร้านขายยาใกล้ฉัน',
    },
};

const getSliderConfig = (serviceCount: number) => ({
    arrows: false,
    dots: false,
    infinite: serviceCount > 4,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
        { breakpoint: 1400, settings: { slidesToShow: 3, infinite: serviceCount > 3 } },
        { breakpoint: 1200, settings: { slidesToShow: 2, infinite: serviceCount > 2 } },
        { breakpoint: 992, settings: { slidesToShow: 4, infinite: serviceCount > 4 } },
        { breakpoint: 768, settings: { slidesToShow: 3, infinite: serviceCount > 3 } },
        { breakpoint: 576, settings: { slidesToShow: 2, infinite: serviceCount > 2 } },
        { breakpoint: 374, settings: { slidesToShow: 1, infinite: serviceCount > 1 } },
    ],
});

export default function ServiceRelate({ serviceList = [] }: Readonly<{ serviceList: string[] }>) {
    const navigate = useNavigate();
    const sliderConfig = useMemo(() => getSliderConfig(serviceList.length), [serviceList.length]);

    // Memoized rendering of services
    const renderedServices = useMemo(() => {
        return serviceList.map((serviceKey, index) => {
            const service = serviceListConfig[serviceKey];
            if (!service) return null; // Guard clause for missing service

            // Special handling for storeNearby
            const servicePath =
                window.location.origin.includes('fastcare.co') && serviceKey === 'storeNearby'
                    ? '/store/nearby?filter=true&type=WALK_IN*GATE_WAY*VIDEO_CALL&voucher=fastcare-storenearby'
                    : service.path;

            return (
                <div key={index} onClick={() => navigate(servicePath + '#')}>
                    <img src={service.desktop} alt={service.alt} title={service.alt} className='slide-desktop' />
                    <img src={service.mobile} alt={service.alt} title={service.alt} className='slide-mobile' />
                </div>
            );
        });
    }, [serviceList, navigate]);

    return (
        <ServiceRelateStyle>
            <div className='landing-panel'>
                <h2 className='title'>บริการที่เกี่ยวข้อง</h2>
                <div className='landing-content'>
                    <div className='service-slide'>
                        <Slider {...sliderConfig}>{renderedServices}</Slider>
                    </div>
                    <div className='all-service' onClick={() => navigate('/home#')}>
                        <span>ดูบริการทั้งหมด</span>
                        <IconConfigWithTheme pageName='service_relate' iconName='see_more' />
                    </div>
                </div>
            </div>
        </ServiceRelateStyle>
    );
}
