import React from 'react';
import Slider from 'react-slick';
import { ServiceSliderStyle } from './Landing.styled';

const getSliderConfig = (serviceCount = 0) => {
    return {
        arrows: false,
        dots: false,
        infinite: serviceCount > 4,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    infinite: serviceCount > 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    infinite: serviceCount > 3,
                },
            },
            {
                breakpoint: 374,
                settings: {
                    slidesToShow: 2,
                    infinite: serviceCount > 2,
                },
            },
        ],
    };
};

export default function ServiceSlideBox({ serviceList = [], title }: Readonly<{ serviceList: any[]; title: string }>) {
    const sliderConfig = getSliderConfig(serviceList.length);
    return (
        <ServiceSliderStyle>
            <div className='landing-panel'>
                <h3 className='title'>{title}</h3>
                <div className='landing-content'>
                    <div className='service-slide'>
                        <Slider {...sliderConfig}>
                            {serviceList.map((service, index) => (
                                <img src={service.img} alt={service.alt} title={service.title} className='img' key={index} />
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </ServiceSliderStyle>
    );
}
