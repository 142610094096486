import React, { Component } from 'react';
import LiftUpComponent from './lift-up-component';

export class LiftUpWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lifts: [], // includes multiple models
        };

        this.totalIndex = 0;
        this.nextCount = 0;
    }

    // control from utils
    open = ({ ...args }) => {
        console.log('open');
        const lift = { ...args };
        let { lifts } = this.state;

        // isVisible controls the visibility of bottomsheet
        this.totalIndex++;
        this.nextCount++;

        // lift.id = `exp-liftup-${this.totalIndex}`
        lift.id = `exp-liftup-${this.nextCount}`;

        // ref controls the lift behaviour, like closing the lift our update the lift
        if (!lift.ref) {
            lift.ref = React.createRef();
        }
        if (!lift.onClose) {
            lift.onClose = () => null;
        }

        lifts.push({ ...lift });
        this.setState({ lifts });
        console.log('lifts:', lifts);

        return {
            id: lift.id,
        };
    };

    // control from utils
    // close = (index = this.state.lifts.length - 1) => {
    close = (id, closeby) => {
        console.log('closeby:', closeby);
        console.log('id:', id);
        let { lifts } = this.state;
        console.log('lifts:', lifts);

        if (!id) return;

        // setTimeout(() => {
        const indexOf = lifts.findIndex((f) => f.id === id);

        if (indexOf < 0) return;

        lifts.splice(indexOf, 1);
        this.setState({ lifts });
        // }, 200);

        // *** how to check close = false or onClose
        // in order to retain close effect
        // if (lifts[index]) {
        //     lifts[index].onClose()
        //     this.setState({ lifts })
        // }
    };

    // control from utils
    closeAll = () => {
        let { lifts } = this.state;

        // clear all lift
        if (lifts.length > 0) lifts.splice(0, lifts.length);

        this.setState({ lifts });
    };

    // control from utils
    updateProps = ({ ...props }, id) => {
        const { lifts } = this.state;

        const indexOfLift = lifts.findIndex((f) => f.id === id);
        lifts[indexOfLift] = { ...lifts[indexOfLift], ...props };
        this.setState({ lifts });
    };
    // updateProps = ({ ...props }, index = this.state.lifts.length - 1) => {
    //     const { lifts } = this.state;
    //     lifts[index] = { ...lifts[index], ...props };
    //     this.setState({ lifts });
    // };

    render() {
        const { lifts } = this.state;
        return (
            <>
                {lifts.map((lift, index) => {
                    console.log('lift:', lift);

                    return <LiftUpComponent key={lift.id + '' + index} close={(e) => this.close(lift.id, e)} {...lift} />;
                })}
            </>
        );
    }
}
