import React, { Suspense, useEffect } from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import PublicRoute from './switch-route/public-route';
import ProtectRoute from './switch-route/protect-route';
import useWindowSize from 'hooks/useWindowSize';
import { SERVICE_CONFIG } from 'constants/service-config';
import PageLoading from 'components/common/page-loading';
import { TelepharmacyService } from './route-list/telepharmacy-service';
import { TelemedService } from './route-list/telemed-service';
import { PhysiotherapyService } from './route-list/physiotherapy-service';
import { NutritionistService } from './route-list/nutritionist-service';
import { TlmService } from './route-list/tlm-service';
import useAuth from 'hooks/useAuth';
import { MetaTags } from 'react-meta-tags';
import { clearUserLocalStorage, cryptojs } from 'utils';
import { If, Then } from 'components/common/if-condition';
import { Else, ElseIf } from 'components/common/if-condition/if-condition';
import { FulfillmentService } from './route-list/fulfillment-service';
import { DEFAULT_THEME, PUBLIC_ROUTES_WITH_YAPHROM_FASTCARE, VOUCHER_WITH_PATHNAME } from 'constants/default-config';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import { MentalHealthService } from './route-list/mth-service';
import { Home, HomeAlive, HomePlugin } from 'pages/Home';
import Demo from 'pages/template/Demo';
import { TlmNcdService } from './route-list/tlm-ncd-service';
import SelectLabAddress from 'pages/task/SelectLabAddress';
import { TLPServiceLanding } from 'pages/LandingService/TLPServiceLanding';
import { ATKServiceLanding } from 'pages/LandingService/ATKServiceLanding';
import { TelemedServiceLanding } from 'pages/LandingService/TelemedServiceLanding';
import { NTServiceLanding } from 'pages/LandingService/NTServiceLanding';
import { PTServiceLanding } from 'pages/LandingService/PTServiceLanding';
import { MTHServiceLanding } from 'pages/LandingService/MTHServiceLanding';
import { TLPConsultServiceLanding } from 'pages/LandingService/TLPConsultServiceLanding';
import { TLPC90ServiceLanding } from 'pages/LandingService/TLPC90ServiceLanding';
import { TLMC13ServiceLanding } from 'pages/LandingService/TLMC13ServiceLanding';
import { TLMNCDServiceLanding } from 'pages/LandingService/TLMNCDServiceLanding';
import { TLPLTCServiceLanding } from 'pages/LandingService/TLPLTCServiceLanding';
// import Home from 'pages/Home/demo'

// import page

const MappingCpa = React.lazy(() => import('pages/mapping-cpa'));
// user
const User = React.lazy(() => import('pages/user'));
// const UserPlugin = React.lazy(() => import('pages/user-plugin'));
// login
const LineOauthCallback = React.lazy(() => import('pages/user-login/line-oauth-callback'));
const LineIncompleteOa = React.lazy(() => import('pages/user-login/line-incomplete-oa'));
const LoginLine = React.lazy(() => import('pages/user-login/login-line'));
const Login = React.lazy(() => import('pages/user-login/login'));
// signup
const Signup = React.lazy(() => import('pages/user-signup/signup'));
const SignupLine = React.lazy(() => import('pages/user-signup/signup-line'));
const SignupVerificationPending = React.lazy(() => import('pages/user-signup/signup-verification-pending'));
const SignupVerification = React.lazy(() => import('pages/user-signup/signup-verification'));
// password
const PasswordForget = React.lazy(() => import('pages/reset-password/password-forget'));
const PasswordReset = React.lazy(() => import('pages/reset-password/password-reset'));
// user profile
const UserAccount = React.lazy(() => import('pages/user-account'));
// user setting
const Profile = React.lazy(() => import('pages/user-account/setting/profile'));
const AddressAdd = React.lazy(() => import('pages/user-account/setting/address/address-add'));
const AddressEdit = React.lazy(() => import('pages/user-account/setting/address/address-edit'));
const CreditCardList = React.lazy(() => import('pages/user-account/setting/credit-card/credit-card-list'));
const CreditCardEdit = React.lazy(() => import('pages/user-account/setting/credit-card/credit-card-edit'));
const Coverage = React.lazy(() => import('pages/user-account/setting/coverage/coverage'));
// terms & conditions
const TermsAndConditions = React.lazy(() => import('pages/terms-conditions'));
const PrivacyPolicy = React.lazy(() => import('pages/privacy-policy'));
// phr existing
const Existing = React.lazy(() => import('pages/task/phr-existing/existing'));
const ExistingPersonalInfo = React.lazy(() => import('pages/task/phr-existing/existing-personal-info'));
const ExistingHealthInfo = React.lazy(() => import('pages/task/phr-existing/existing-health-info'));
const ExistingAdditionalInfo = React.lazy(() => import('pages/task/phr-existing/existing-additional-info'));
const ExistingLab = React.lazy(() => import('pages/task/phr-existing/existing-lab'));
const ExistingLabDetail = React.lazy(() => import('pages/task/phr-existing/existing-lab-detail'));
const ExistingPharmacistNoteList = React.lazy(() => import('pages/task/phr-existing/existing-pharmacist-note-list'));
const ExistingPharmacistNoteDetail = React.lazy(() => import('pages/task/phr-existing/existing-pharmacist-note-detail'));
// service
const Service = React.lazy(() => import('pages/service'));
// pharmacy store
const StoreNearby = React.lazy(() => import('pages/store-nearby/nearbyV1'));
const StoreNearbyTemplate = React.lazy(() => import('pages/store-nearby/Nearby'));
const StoreFavourite = React.lazy(() => import('pages/store-nearby/favourite'));
const StoreDetail = React.lazy(() => import('pages/store-nearby/detail'));
// client error responses
const NotFound404 = React.lazy(() => import('pages/client-error-responses/notfound-404'));
//browserNotSupported
const BrowserNotSupported = React.lazy(() => import('pages/client-error-responses/browser-not-supported'));
// template
const Template = React.lazy(() => import('pages/template/template'));
const TemplateTheme = React.lazy(() => import('pages/template/template-theme'));
const Encode = React.lazy(() => import('pages/template/encode'));
const LiffSDK = React.lazy(() => import('pages/template/liff-sdk'));
const TemplateWebview = React.lazy(() => import('pages/template/template-webview'));
const Template1 = React.lazy(() => import('pages/template/template1'));
const Template2 = React.lazy(() => import('pages/template/template2'));
const TemplateTokbox = React.lazy(() => import('pages/template/template-tokbox'));
const TemplateTokboxChiiwii = React.lazy(() => import('pages/template/template-tokbox-chiiwii'));
const TemplateUserSsoTest = React.lazy(() => import('pages/template/template-user-sso-test'));
const TemplateForm = React.lazy(() => import('pages/template/template-form'));
// article
const Article = React.lazy(() => import('pages/article/article'));
const ArticleEdit = React.lazy(() => import('pages/article/article-edit'));
const ArticleDetail = React.lazy(() => import('pages/article/article-detail'));
const ArticleManagement = React.lazy(() => import('pages/article/article-management'));
// lift
const Liff = React.lazy(() => import('pages/liff/liff'));
//new fulfillment
const History = React.lazy(() => import('pages/service-history/history'));
const UserSso = React.lazy(() => import('pages/user-sso'));
// const TLPServiceLanding = React.lazy(() => import('pages/LandingService/TLPServiceLanding').then((module) => module.TLPServiceLanding));
// const TLPC90ServiceLanding = React.lazy(() => import('pages/LandingService/tlp-c90'));
// const TLMServiceLanding = React.lazy(() => import('pages/LandingService/TelemedServiceLanding'));
// const MTHServiceLanding = React.lazy(() => import('pages/LandingService/mth'));
// const TLMC13ServiceLanding = React.lazy(() => import('pages/LandingService/tlm-c13'));
// const TLMNCDServiceLanding = React.lazy(() => import('pages/LandingService/tlm-ncd'));
// const TLPConsultServiceLanding = React.lazy(() => import('pages/LandingService/tlp-consult'));
// const ATKServiceLanding = React.lazy(() => import('pages/LandingService/ATKServiceLanding').then((module) => module.ATKServiceLanding));
// const PTServiceLanding = React.lazy(() => import('pages/LandingService/PTServiceLanding'));
// const NTServiceLanding = React.lazy(() => import('pages/LandingService/NTServiceLanding'));
// const TLPLTCServiceLanding = React.lazy(() => import('pages/LandingService/TLPLTCServiceLanding'));
// redirect page
const LineToBrowser = React.lazy(() => import('pages/redirect/line-to-browser'));
const FBStoreDetailToLine = React.lazy(() => import('pages/redirect/fb-store-detail-to-line'));
const FBStoreDetailFavouriteToLine = React.lazy(() => import('pages/redirect/fb-to-store-detail-favourite-to-line'));
const FBToLine = React.lazy(() => import('pages/redirect/fb-to-line'));
const FBServiceToLine = React.lazy(() => import('pages/redirect/fb-service-to-line'));
const ToClaim = React.lazy(() => import('pages/redirect/to-claim'));
const Partner20ToRedirect = React.lazy(() => import('pages/redirect/partner20'));
const PDFBase64ToBlob = React.lazy(() => import('pages/redirect/pdf-base64-to-blob'));

//
const REDIRECT_PATH = [{ nextPath: '/task/claim/telepharmacy', reactPath: '/telepharmacy/claim' }];
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function NavigateWithParams({ to }) {
    return <Navigate to={to + window.location.search} replace={true} />;
}

function RedirectPageToUserLogin() {
    React.useEffect(() => {
        let to = { path: '/user/plugin', data: { partner: '18', autoLogin: 0 } };
        if (window.location.origin.includes('yaphrom')) {
            to = { path: '/user/plugin', data: { partner: '15', autoLogin: 0 } };
        }

        clearUserLocalStorage();

        return window.location.replace('/user/line?to=' + cryptojs.encode(to));
    });

    return <h5>Redirecting...</h5>;
}

function RoutesManagement() {
    const theme = useThemeWithPartner();
    const { width, height } = useWindowSize();
    const { auth, profile } = useAuth();

    // set body size
    useEffect(() => {
        const setCssRoot = () => {
            document.querySelector(':root').style.setProperty('--body-width', width + 'px');
            document.querySelector(':root').style.setProperty('--body-height', height + 'px');
        };
        setCssRoot();

        window.addEventListener('setCssRoot', setCssRoot);

        return () => window.removeEventListener('setCssRoot', setCssRoot);
    }, [width, height]);

    // return

    if (auth.status === 'WAITING') {
        return (
            <SentryRoutes>
                <Route path='*' element={<PageLoading type='spinner' active={true} />} />
            </SentryRoutes>
        );
    }

    if (
        !auth.isAuth &&
        (window.location.origin.includes('fastcare.co') || window.location.origin.includes('yaphrom')) &&
        PUBLIC_ROUTES_WITH_YAPHROM_FASTCARE.every((pathname) => window.location.pathname !== pathname)
    ) {
        return <RedirectPageToUserLogin />;
    }

    return (
        <>
            {auth.status === 'FINISH' && (
                <>
                    {profile.theme && (
                        <If condition={profile.theme === 'yaphrom'}>
                            <Then>
                                <MetaTags>
                                    <title>Yaphrom &#9168; ร้านยาคุณภาพ พร้อมส่งยาถึงบ้านคุณ</title>
                                    <link rel='icon' href='/images/logo/yaphrom-logo.png' />
                                </MetaTags>
                            </Then>
                            <ElseIf condition={profile.theme === 'central'}>
                                <MetaTags>
                                    <title>Tops Care &#9168; ร้านยาคุณภาพ พร้อมส่งยาถึงบ้านคุณ</title>
                                </MetaTags>
                            </ElseIf>
                            <ElseIf condition={profile.theme === 'livewell'}>
                                <MetaTags>
                                    <title>LIVEWELL &#9168; ร้านยาคุณภาพ พร้อมส่งยาถึงบ้านคุณ</title>
                                </MetaTags>
                            </ElseIf>
                            <ElseIf condition={profile.theme === 'morningmind'}>
                                <MetaTags>
                                    <title>MorningMind &#9168; มอร์นิ่งมายด์คลินิกเฉพาะทางเวชกรรมจิตเวช</title>
                                </MetaTags>
                            </ElseIf>

                            <ElseIf condition={profile.theme === 'fastcare'}>
                                <MetaTags>
                                    <title>FastCare &#9168; ร้านยาคุณภาพ พร้อมส่งยาถึงบ้านคุณ</title>
                                    <link rel='icon' href='/images/logo/fastcare2.png' />
                                </MetaTags>
                            </ElseIf>
                            <Else>
                                <MetaTags>
                                    <title>PharmCare &#9168; ร้านยาคุณภาพ พร้อมส่งยาถึงบ้านคุณโดย PharmCare</title>
                                </MetaTags>
                            </Else>
                        </If>
                    )}
                    <If condition={window.location.origin.includes('fastcare.co')}>
                        <Then>
                            <MetaTags>
                                <title>FastCare &#9168; ร้านยาคุณภาพ พร้อมส่งยาถึงบ้านคุณ</title>
                                <link rel='icon' href='/images/logo/fastcare2.png' />
                            </MetaTags>
                        </Then>
                        <ElseIf condition={window.location.origin.includes('yaphrom')}>
                            <MetaTags>
                                <title>Yaphrom &#9168; ร้านยาคุณภาพ พร้อมส่งยาถึงบ้านคุณ</title>
                                <link rel='icon' href='/images/logo/yaphrom-logo.png' />
                            </MetaTags>
                        </ElseIf>
                    </If>
                </>
            )}
            {window.location.origin.includes('fastcare.co') && (
                <MetaTags>
                    <title>FastCare &#9168; ร้านยาคุณภาพ พร้อมส่งยาถึงบ้านคุณ</title>
                    <link rel='icon' href='/images/logo/fastcare2.png' />
                </MetaTags>
            )}
            {window.location.origin.includes('yaphrom') && (
                <MetaTags>
                    <title>Yaphrom &#9168; ร้านยาคุณภาพ พร้อมส่งยาถึงบ้านคุณ</title>
                    <link rel='icon' href='/images/logo/yaphrom-logo.png' />
                </MetaTags>
            )}
            <Suspense fallback={<PageLoading active />}>
                {/* article */}
                {['ROLE_WRITER'].some((value) => value === auth.role) && (
                    <SentryRoutes>
                        <Route path='/article/add' element={<PublicRoute component={ArticleEdit} />} />
                        <Route path='/article/edit/:articleid' element={<PublicRoute component={ArticleEdit} />} />
                        <Route path='/article-management/:page?' element={<PublicRoute component={ArticleManagement} />} />
                        <Route path='*' element={<PublicRoute component={NotFound404} />} />
                    </SentryRoutes>
                )}
                {['ROLE_ADMIN', 'ROLE_USER', 'PUBLIC'].some((value) => value === auth.role) && (
                    <SentryRoutes>
                        {REDIRECT_PATH.map((path, index) => (
                            <Route key={index} path={path.nextPath} element={<NavigateWithParams to={path.reactPath} />} />
                        ))}
                        {VOUCHER_WITH_PATHNAME.map((items, index) => (
                            <Route key={index} path={items.path} element={<PublicRoute component={ToClaim} redirect={items.redirect} />} />
                        ))}
                        {profile.theme.includes('default') && (
                            <>
                                <Route path='/home-alive' element={<PublicRoute component={HomeAlive} requireMobileLanguage />} />
                                <Route path='/home-ohc' element={<PublicRoute component={HomeAlive} requireMobileLanguage />} />
                            </>
                        )}
                        {auth.role === 'ROLE_ADMIN' && (
                            <>
                                <Route path='/article/add' element={<ProtectRoute component={ArticleEdit} />} />
                                <Route path='/article/edit/:articleid' element={<ProtectRoute component={ArticleEdit} />} />
                                <Route path='/article-management/:page?' element={<ProtectRoute component={ArticleManagement} />} />
                            </>
                        )}

                        <Route path='/telepharmacy/home' element={<NavigateWithParams to='/home' />} />
                        <Route path='/fulfillment/home' element={<NavigateWithParams to='/home' />} />
                        <Route path='/telemed/home' element={<NavigateWithParams to='/home' />} />
                        <Route exact path='/'>
                            {DEFAULT_THEME.every((s) => profile.theme !== s) ? (
                                <>
                                    <Route index element={<NavigateWithParams to={'/user/plugin'} />} />
                                    <Route path='home' element={<NavigateWithParams to={'/user/plugin'} />} />
                                </>
                            ) : (
                                <Route index element={<PublicRoute component={Home} requireMobileLanguage googleAnalytics />} />
                            )}
                            <Route
                                path='home/tlp/:voucher'
                                element={<PublicRoute component={Home} requireMobileLanguage googleAnalytics />}
                            />
                            <Route path='home/:voucher?' element={<PublicRoute component={Home} requireMobileLanguage googleAnalytics />} />
                        </Route>

                        {/* Terms & Conditions*/}
                        <Route path='/terms-conditions' element={<PublicRoute component={TermsAndConditions} />} />
                        {/* privacy & policy */}
                        <Route path='/privacy-policy' element={<PublicRoute component={PrivacyPolicy} />} />

                        {/* landing */}
                        {/* <Route path='/service/telepharmacy/c90' element={<NavigateWithParams to='/landing/telepharmacy/c90' />} />
                        <Route path='/service/telepharmacy' element={<NavigateWithParams to='/landing/telepharmacy' />} />
                        <Route path='/service/telemed/c13' element={<NavigateWithParams to='/landing/telemed/c13' />} />
                        <Route path='/service/telemed' element={<NavigateWithParams to='/landing/telemed' />} />
                        <Route path='/service/telepharmacy-consult' element={<NavigateWithParams to='/landing/telepharmacy-consult' />} />
                        <Route path='/service/atk' element={<NavigateWithParams to='/landing/atk' />} />
                        <Route path='/service/physiotherapy' element={<NavigateWithParams to='/landing/physiotherapy' />} />
                        <Route path='/service/nutritionist' element={<NavigateWithParams to='/landing/nutritionist' />} /> */}

                        <Route path='/service/telepharmacy/c90' element={<NavigateWithParams to='/service/telepharmacy' />} />

                        <Route
                            path='/service/telepharmacy/c90'
                            element={<PublicRoute component={TLPC90ServiceLanding} requireRemoteConfig requireMobileLanguage />}
                        />
                        <Route
                            path='/service/telepharmacy/lct'
                            element={<PublicRoute component={TLPLTCServiceLanding} requireRemoteConfig requireMobileLanguage />}
                        />
                        <Route
                            path='/service/telepharmacy'
                            element={<PublicRoute component={TLPServiceLanding} requireRemoteConfig requireMobileLanguage />}
                        />
                        <Route
                            path='/service/telemed/c13'
                            element={<PublicRoute component={TLMC13ServiceLanding} requireRemoteConfig requireMobileLanguage />}
                        />
                        <Route
                            path='/service/telemed-ncd'
                            element={<PublicRoute component={TLMNCDServiceLanding} requireRemoteConfig requireMobileLanguage />}
                        />
                        <Route
                            path='/service/telemed'
                            element={<PublicRoute component={TelemedServiceLanding} requireRemoteConfig requireMobileLanguage />}
                        />
                        <Route
                            path='/service/mentalhealth'
                            element={<PublicRoute component={MTHServiceLanding} requireRemoteConfig requireMobileLanguage />}
                        />
                        <Route
                            path='/service/telepharmacy-consult'
                            element={<PublicRoute component={TLPConsultServiceLanding} requireRemoteConfig requireMobileLanguage />}
                        />
                        <Route
                            path='/service/atk'
                            element={<PublicRoute component={ATKServiceLanding} requireRemoteConfig requireMobileLanguage />}
                        />
                        <Route
                            path='/service/physiotherapy'
                            element={<PublicRoute component={PTServiceLanding} requireRemoteConfig requireMobileLanguage />}
                        />
                        <Route
                            path='/service/nutritionist'
                            element={<PublicRoute component={NTServiceLanding} requireRemoteConfig requireMobileLanguage />}
                        />

                        {/* redirect */}
                        <Route path='/fb/line/redirect' element={<PublicRoute component={FBToLine} isLoadPage />} />
                        <Route path='/fb/service/:serviceName' element={<PublicRoute component={FBServiceToLine} />} />
                        <Route
                            path='/fb/store/detail/favourite'
                            element={<PublicRoute component={FBStoreDetailFavouriteToLine} isLoadPage />}
                        />
                        <Route
                            path='/fb/store/detail/favourite/:pharmacyCode'
                            element={<PublicRoute component={FBStoreDetailFavouriteToLine} isLoadPage />}
                        />
                        <Route path='/fb/store/detail/:pharmacyCode' element={<PublicRoute component={FBStoreDetailToLine} />} />
                        <Route path='/line/redirect/:data?' element={<PublicRoute component={LineToBrowser} isLoadPage />} />
                        <Route path='/report/pdf' element={<PublicRoute component={PDFBase64ToBlob} isLoadPage />} />
                        <Route
                            path='/partner20/redirect/:redirectPath'
                            element={<PublicRoute component={Partner20ToRedirect} isLoadPage />}
                        />

                        {/* store */}
                        <Route
                            path='template-nearby'
                            element={<PublicRoute component={StoreNearbyTemplate} filter={'WALK_IN*GATE_WAY*VIDEO_CALL'} isLoadPage />}
                        />
                        <Route path='/store'>
                            <Route index element={<PublicRoute component={StoreNearby} filter={'WALK_IN*GATE_WAY*VIDEO_CALL'} />} />
                            <Route
                                path='nearby'
                                element={<PublicRoute component={StoreNearby} filter={'WALK_IN*GATE_WAY*VIDEO_CALL'} isLoadPage />}
                            />
                            <Route
                                path='nearby'
                                element={<PublicRoute component={StoreNearby} filter={'WALK_IN*GATE_WAY*VIDEO_CALL'} isLoadPage />}
                            />
                            <Route path='detail'>
                                <Route index element={<PublicRoute component={StoreDetail} requireRemoteConfig isLoadPage />} />
                                <Route
                                    path='favourite/:pharmacy?'
                                    element={<PublicRoute component={StoreDetail} requireRemoteConfig isLoadPage />}
                                />
                                <Route path=':pharmacy' element={<PublicRoute component={StoreDetail} requireRemoteConfig isLoadPage />} />
                            </Route>
                            <Route path='favourite' element={<ProtectRoute component={StoreFavourite} requireRemoteConfig isLoadPage />} />
                        </Route>
                        <Route path='/mapping/cpa' element={<PublicRoute component={MappingCpa} isLoadPage />} />
                        {/* fda */}
                        <Route path='/fda' element={<PublicRoute component={StoreNearby} isLoadPage />} />
                        {/* liff */}
                        <Route path='/liff' element={<Route component={Liff} />} />
                        {/* article */}
                        <Route path='/article'>
                            <Route index element={<PublicRoute component={Article} />} />
                            <Route path='detail/:articleid/:seo' element={<PublicRoute component={ArticleDetail} />} />
                            <Route path=':page' element={<PublicRoute component={Article} />} />
                        </Route>
                        {/* user */}
                        <Route path='/user'>
                            <Route index element={<PublicRoute component={User} />} />
                            <Route path='sso' element={<PublicRoute component={UserSso} requireNavebar={false} />} />
                            <Route path='plugin/:path?' element={<ProtectRoute component={HomePlugin} requireMobileLanguage />} />
                            <Route path='login/:path?' element={<PublicRoute component={Login} />} />
                            <Route path='line/:path?' element={<PublicRoute component={LoginLine} isLoadPage />} />
                            <Route path='signup' element={<PublicRoute component={Signup} />} />
                            <Route path='signup/line' element={<PublicRoute component={SignupLine} />} />
                            <Route
                                path='verification-pending'
                                element={<PublicRoute component={SignupVerificationPending} requireOnlyPublic pageLoadingType='rocket' />}
                            />
                            <Route
                                path='forgetpassword'
                                element={<PublicRoute component={PasswordForget} requireOnlyPublic pageLoadingType='rocket' />}
                            />
                            <Route
                                path='resetpassword'
                                element={<PublicRoute component={PasswordReset} requireOnlyPublic pageLoadingType='rocket' />}
                            />
                            <Route
                                path='verification'
                                element={<PublicRoute component={SignupVerification} requireOnlyPublic pageLoadingType='rocket' />}
                            />
                            <Route path='account'>
                                <Route index element={<ProtectRoute component={UserAccount} />} />
                                <Route path='setting/profile' element={<ProtectRoute component={Profile} />} />
                                <Route path='setting/address/add' element={<ProtectRoute component={AddressAdd} isLoadPage />} />
                                <Route
                                    path='setting/address/edit/:addressId'
                                    element={<ProtectRoute component={AddressEdit} isLoadPage />}
                                />
                                <Route path='setting/coverage/:companyCode' element={<ProtectRoute component={Coverage} />} />
                                <Route path='setting/creditcard/new' element={<ProtectRoute component={CreditCardEdit} />} />
                                <Route path='setting/creditcard/list' element={<ProtectRoute component={CreditCardList} />} />
                            </Route>
                        </Route>

                        {/* oauth2callback */}
                        <Route
                            path='/line/incomplete-oa'
                            element={
                                <PublicRoute
                                    component={LineIncompleteOa}
                                    requireOnlyPublic
                                    requireInProgress
                                    requireNavebar={false}
                                    pageLoadingType='rocket'
                                />
                            }
                        />
                        <Route path='/line/oauth2callback' element={<PublicRoute component={LineOauthCallback} isLoadPage />} />

                        {/* phr */}
                        <Route path='/phr/existing'>
                            <Route index element={<ProtectRoute isLoadPage component={Existing} />} />
                            <Route path='personal-info' element={<ProtectRoute component={ExistingPersonalInfo} isLoadPage />} />
                            <Route path='health-info' element={<ProtectRoute component={ExistingHealthInfo} isLoadPage />} />
                            <Route path='additional-info' element={<ProtectRoute component={ExistingAdditionalInfo} isLoadPage />} />
                            <Route path='lab' element={<ProtectRoute component={ExistingLab} isLoadPage />} />
                            <Route path='lab/:detail' element={<ProtectRoute component={ExistingLabDetail} isLoadPage />} />
                            <Route
                                path='pharmacist-note/list/:cpaType'
                                element={<ProtectRoute component={ExistingPharmacistNoteList} isLoadPage />}
                            />
                            <Route
                                path='pharmacist-note/detail/:detailType/:cpaType/:cpaTID'
                                element={<ProtectRoute component={ExistingPharmacistNoteDetail} isLoadPage />}
                            />
                        </Route>

                        {/* service */}
                        <Route path='/service' element={<NavigateWithParams to='/activities' />} />
                        <Route path='/activities' element={<ProtectRoute exact isLoadPage component={Service} requireNavebar={false} />} />

                        {/* report */}
                        {/* <Route path='/service/report/tlp' element={<NavigateWithParams to='/report/telepharmacy' />} />
                        <Route path='/service/report/tlm' element={<NavigateWithParams to={'/report/telemed'} />} />
                        <Route path='/service/report/ful' element={<NavigateWithParams to='/report/fulfillment' />} />
                        <Route path='/service/report/pt' element={<NavigateWithParams to='/report/physiotherapy' />} />
                        <Route path='/service/report/nt' element={<NavigateWithParams to='/report/nutritionist' />} /> */}
                        <Route
                            path='/service/report/tlp'
                            element={
                                <ProtectRoute service={SERVICE_CONFIG.telepharmacy} isLoadPage component={History} requireNavebar={false} />
                            }
                        />
                        <Route
                            path='/service/report/tlm'
                            element={<ProtectRoute isLoadPage service={SERVICE_CONFIG.tlm} component={History} requireNavebar={false} />}
                        />
                        <Route
                            path='/service/report/mth'
                            element={<ProtectRoute isLoadPage service={SERVICE_CONFIG.mth} component={History} requireNavebar={false} />}
                        />
                        <Route
                            path='/service/report/pt'
                            element={
                                <ProtectRoute
                                    isLoadPage
                                    service={SERVICE_CONFIG.physiotherapy}
                                    component={History}
                                    requireNavebar={false}
                                />
                            }
                        />
                        <Route
                            path='/service/report/nt'
                            element={
                                <ProtectRoute isLoadPage service={SERVICE_CONFIG.nutritionist} component={History} requireNavebar={false} />
                            }
                        />
                        <Route
                            path='/service/report/ful'
                            element={<ProtectRoute service={SERVICE_CONFIG.fulfillment} component={History} requireNavebar={false} />}
                        />

                        {/* telepharmacy */}
                        {TelepharmacyService.map((items, index) => (
                            <Route
                                key={index}
                                path={items.path}
                                element={
                                    <ProtectRoute
                                        exact={items.exact}
                                        isLoadPage={items.isLoadPage}
                                        component={items.component}
                                        service={items.service}
                                        progress={items.progress}
                                        pharmcareProgram={items.pharmcareProgram}
                                        requireNavebar={items.requireNavebar}
                                        requireParamsTask={items.requireParamsTask}
                                        requireInProgress={items.requireInProgress}
                                        requireRemoteConfig={items.requireRemoteConfig}
                                        requireMobileLanguage={items.requireMobileLanguage}
                                    />
                                }
                            />
                        ))}
                        {/* fulfillment */}
                        {FulfillmentService.map((items, index) => (
                            <Route
                                key={index}
                                path={items.path}
                                element={
                                    <ProtectRoute
                                        exact={items.exact}
                                        isLoadPage={items.isLoadPage}
                                        path={items.path}
                                        component={items.component}
                                        service={items.service}
                                        progress={items.progress}
                                        pharmcareProgram={items.pharmcareProgram}
                                        requireNavebar={items.requireNavebar}
                                        requireParamsTask={items.requireParamsTask}
                                        requireInProgress={items.requireInProgress}
                                        requireRemoteConfig={items.requireRemoteConfig}
                                        requireMobileLanguage={items.requireMobileLanguage}
                                    />
                                }
                            />
                        ))}
                        {/* telemed */}
                        {TelemedService.map((s, index) => (
                            <Route
                                key={index}
                                path={s.path}
                                element={
                                    <ProtectRoute
                                        exact={s.exact}
                                        isLoadPage={s.isLoadPage}
                                        component={s.component}
                                        service={s.service}
                                        progress={s.progress}
                                        pharmcareProgram={s.pharmcareProgram}
                                        requireNavebar={s.requireNavebar}
                                        requireParamsTask={s.requireParamsTask}
                                        requireInProgress={s.requireInProgress}
                                        requireRemoteConfig={s.requireRemoteConfig}
                                        requireMobileLanguage={s.requireMobileLanguage}
                                    />
                                }
                            />
                        ))}
                        {/* mental health */}
                        {MentalHealthService.map((s, index) => (
                            <Route
                                key={index}
                                path={s.path}
                                element={
                                    <ProtectRoute
                                        exact={s.exact}
                                        isLoadPage={s.isLoadPage}
                                        component={s.component}
                                        service={s.service}
                                        progress={s.progress}
                                        pharmcareProgram={s.pharmcareProgram}
                                        requireNavebar={s.requireNavebar}
                                        requireParamsTask={s.requireParamsTask}
                                        requireInProgress={s.requireInProgress}
                                        requireRemoteConfig={s.requireRemoteConfig}
                                        requireMobileLanguage={s.requireMobileLanguage}
                                    />
                                }
                            />
                        ))}
                        {/* pt */}
                        {PhysiotherapyService.map((k, index) => (
                            <Route
                                key={index}
                                path={k.path}
                                element={
                                    <ProtectRoute
                                        exact={k.exact}
                                        isLoadPage={k.isLoadPage}
                                        component={k.component}
                                        service={k.service}
                                        progress={k.progress}
                                        pharmcareProgram={k.pharmcareProgram}
                                        requireNavebar={k.requireNavebar}
                                        requireParamsTask={k.requireParamsTask}
                                        requireInProgress={k.requireInProgress}
                                        requireRemoteConfig={k.requireRemoteConfig}
                                        requireMobileLanguage={k.requireMobileLanguage}
                                    />
                                }
                            />
                        ))}
                        {/* nt */}
                        {NutritionistService.map((j, index) => (
                            <Route
                                key={index}
                                path={j.path}
                                element={
                                    <ProtectRoute
                                        exact={j.exact}
                                        isLoadPage={j.isLoadPage}
                                        component={j.component}
                                        service={j.service}
                                        progress={j.progress}
                                        pharmcareProgram={j.pharmcareProgram}
                                        requireNavebar={j.requireNavebar}
                                        requireParamsTask={j.requireParamsTask}
                                        requireInProgress={j.requireInProgress}
                                        requireRemoteConfig={j.requireRemoteConfig}
                                        requireMobileLanguage={j.requireMobileLanguage}
                                    />
                                }
                            />
                        ))}
                        {/* TLM */}
                        {TlmService.map((f, index) => (
                            <Route
                                key={index}
                                path={f.path}
                                element={
                                    <ProtectRoute
                                        exact={f.exact}
                                        isLoadPage={f.isLoadPage}
                                        component={f.component}
                                        service={f.service}
                                        progress={f.progress}
                                        pharmcareProgram={f.pharmcareProgram}
                                        requireNavebar={f.requireNavebar}
                                        requireParamsTask={f.requireParamsTask}
                                        requireInProgress={f.requireInProgress}
                                        requireRemoteConfig={f.requireRemoteConfig}
                                        requireMobileLanguage={f.requireMobileLanguage}
                                    />
                                }
                            />
                        ))}
                        {/* TLM-NCD */}
                        {TlmNcdService.map((f, index) => (
                            <Route
                                key={index}
                                path={f.path}
                                element={
                                    <ProtectRoute
                                        exact={f.exact}
                                        isLoadPage={f.isLoadPage}
                                        component={f.component}
                                        service={f.service}
                                        progress={f.progress}
                                        pharmcareProgram={f.pharmcareProgram}
                                        requireNavebar={f.requireNavebar}
                                        requireParamsTask={f.requireParamsTask}
                                        requireInProgress={f.requireInProgress}
                                        requireRemoteConfig={f.requireRemoteConfig}
                                        requireMobileLanguage={f.requireMobileLanguage}
                                    />
                                }
                            />
                        ))}
                        <Route path='/template'>
                            <Route index element={<PublicRoute component={Template} />} />
                            <Route path='theme' element={<PublicRoute component={TemplateTheme} />} />
                            <Route path='encode' element={<PublicRoute component={Encode} />} />
                            <Route path='liff-sdk' element={<PublicRoute component={LiffSDK} />} />
                            <Route
                                path='tokbox/chiiwii'
                                element={<PublicRoute component={TemplateTokboxChiiwii} requireNavebar={false} />}
                            />
                            <Route path='tokbox' element={<PublicRoute component={TemplateTokbox} requireNavebar={false} />} />
                            <Route path='1' element={<PublicRoute component={Template1} requireNavebar={true} />} />
                            <Route path='2' element={<PublicRoute component={Template2} />} />
                            <Route path='webview' element={<PublicRoute component={TemplateWebview} />} />
                            <Route path='user-sso' element={<PublicRoute component={TemplateUserSsoTest} />} />
                            <Route path='form' element={<PublicRoute component={TemplateForm} />} />
                        </Route>
                        <Route path='/demo' element={<PublicRoute component={Demo} />} />
                        <Route path='/select-lab-address' element={<PublicRoute component={SelectLabAddress} />} />

                        <Route path='/browsernotsupport?referer=:data?' element={<PublicRoute component={BrowserNotSupported} />} />

                        <Route path='*' element={<PublicRoute component={NotFound404} />} />
                    </SentryRoutes>
                )}
                {['BAN'].some((value) => value === auth.role) && (
                    <SentryRoutes>
                        <Route path='*' element={<PublicRoute component={NotFound404} />} />
                    </SentryRoutes>
                )}
            </Suspense>
        </>
    );
}

export default RoutesManagement;
