import { Field, FormField } from 'components/common/exp-form';
import EXPPopupJs from 'components/common/exp-popupjs';
import { Col, Row } from 'components/common/grid-layout';
import { TextTitle } from 'components/common/styled-components-common';
import { DEFAULT_LATLNG } from 'constants/default-config';
import { phoneNumberPattern } from 'constants/regex-pattern';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MapsWrapper } from '../SelectLabAddress.styled';
import GoogleMaps, { SearchPlace } from 'components/common/google-maps';
import CurrentLocation from 'components/common/google-maps/current-location';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/button';
import type { AddressData } from '../SelectLabAddress';
import moment from 'moment';

export interface ModalAddEditAddressComponent {
    open: boolean;
    data?: AddressData;
    onClose: () => void;
    onUpdateAddress?: (data: AddressData) => void;
    loading?: boolean;
}

const initialAddressForm = {
    nomineeName: '',
    nomineePhoneNumber: '',
    latitude: DEFAULT_LATLNG.latitude,
    longitude: DEFAULT_LATLNG.longitude,
    address: '',
    province: '',
    district: '',
    subdistrict: '',
    zipcode: '',
    countryCode: '',
    dateOfBirth: '',
};

function ModalAddEditAddress({ loading, open, data, onClose = () => null, onUpdateAddress }: Readonly<ModalAddEditAddressComponent>) {
    const mapsRef = useRef<any>({});
    //
    const [addressForm, setAddressForm] = useState<AddressData>(initialAddressForm);
    const [addressFormDate, setAddressFormDate] = useState<string>('');

    //
    const fx = (e: React.WheelEvent<HTMLDivElement>) => {
        const z = e.deltaY < 0 ? 1 : -1;
        mapsRef.current.setZoom(Math.max(0, Math.min(22, mapsRef.current.getZoom() + z)));
        mapsRef.current.panTo(new window.google.maps.LatLng(addressForm.latitude, addressForm.longitude));
    };

    const onMapsLoaded = useCallback(
        ({ map }) => {
            mapsRef.current = map;
            if (data?.latitude && data?.longitude) {
                findFromLatlng(data.latitude, data.longitude, mapsRef.current);
            }
        },
        [data]
    );

    const findFromLatlng = (lat: number, lng: number, mapApi: { panTo: (arg0: any) => void }) => {
        try {
            mapApi.panTo(new window.google.maps.LatLng(lat, lng));
            new window.google.maps.Geocoder().geocode(
                { location: { lat, lng } },
                (results: { address_components: any; formatted_address: string }[], status: any) => {
                    if (status === window.google?.maps.GeocoderStatus.OK) {
                        let locationTemp = { ...addressForm };
                        locationTemp.latitude = lat;
                        locationTemp.longitude = lng;
                        locationTemp.province = findResult(results[0].address_components, 'administrative_area_level_1');
                        locationTemp.district =
                            findResult(results[0].address_components, 'administrative_area_level_2') ??
                            findResult(results[0].address_components, 'sublocality_level_1');
                        locationTemp.subdistrict =
                            findResult(results[0].address_components, 'sublocality_level_2') ??
                            findResult(results[0].address_components, 'sublocality_level_1') ??
                            findResult(results[0].address_components, 'locality');
                        locationTemp.zipcode = findResult(results[0].address_components, 'postal_code');
                        locationTemp.countryCode = findResult(results[0].address_components, 'country');

                        setAddressForm({ ...locationTemp });
                    }
                }
            );
        } catch (error) {
            console.error('error: ', error);
        }
    };

    const findResult = (value: any[], name: string) => {
        const result = value.find((e: { types: any[] }) => {
            return e.types.find((i: any) => i === name);
        });

        if (result) {
            return result.short_name;
        }
        return null;
    };

    const onSearchPlace = async (even: { geometry: { location: { lat: () => number; lng: () => number } } }) => {
        findFromLatlng(even.geometry.location.lat(), even.geometry.location.lng(), mapsRef.current);
    };

    const onSubmit = (_: any, values: any) => {
        if (onUpdateAddress) {
            onUpdateAddress({ ...data, ...values });
        }
    };
    const onChangeFormDate = (event) => {
        setAddressFormDate(event.target.value);
    };
    useEffect(() => {
        const init = () => {
            if (data?.id) {
                const _data = {
                    ...data,
                    latitude: Number(data.latitude),
                    longitude: Number(data.longitude),
                };
                setAddressForm({ ..._data });
                setAddressFormDate(data.dateOfBirth || '');
            }
        };

        init();

        return () => {
            setAddressForm({ ...initialAddressForm });
        };
    }, [data]);

    return (
        <EXPPopupJs.Jsx show={open} onClose={onClose} maxWidth='900px' maxHeight='90vh'>
            <TextTitle>กรอกข้อมูลชื่อและที่อยู่</TextTitle>
            <FormField initialValues={data} className='text-left' onSubmit={onSubmit as any}>
                <Row>
                    <Col xs={12} sm={12} md={6}>
                        <Field.Control
                            title={'ชื่อ-นามสกุล'}
                            name='nomineeName'
                            placeholder='ระบุชื่อ - นามสกุล'
                            errorMsg='กรุณากรอกชื่อ - สกุล'
                            required
                        />
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <Field.Control
                            title={'เบอร์โทรศัพท์ (มือถือ)'}
                            type='tel'
                            name='nomineePhoneNumber'
                            placeholder='เบอร์โทรศัพท์ (มือถือ)'
                            pattern={phoneNumberPattern}
                            errorMsg='กรุณากรอกเบอร์โทรศัพท์ (มือถือ)'
                            required
                        />
                    </Col>

                    <Col xs={12} sm={12} md={6}>
                        <Field.Date
                            title='วัน/เดือน/ปีเกิด'
                            dateType='datepickerslider'
                            name='dateOfBirth'
                            placeholder='DD/MM/YYYY'
                            formatDate='DD/MM/YYYY'
                            errorMsg='กรุณาเลือก วัน/เดือน/ปีเกิด (ค.ศ.)'
                            min={moment().add(-150, 'year').format('YYYY-MM-DD')}
                            max={moment().add(-1, 'year').endOf('month').endOf('year').format('YYYY-MM-DD')}
                            onChange={onChangeFormDate}
                            value={addressFormDate}
                            required
                        />
                    </Col>
                </Row>

                {/* ------- map ------- */}
                <div className='mt-2'>ปักหมุดที่อยู่</div>
                <MapsWrapper className='custom-map' onWheel={fx} size={'minimize'}>
                    <div className='-search-place-auto'>
                        <SearchPlace className='w-100 mt-1' placeholder={'ค้นหาที่อยู่'} onSelectPlace={onSearchPlace} />
                    </div>
                    <GoogleMaps
                        // markerHereCenter
                        defaultZoom={17}
                        defaultCenter={{ lat: DEFAULT_LATLNG.latitude, lng: DEFAULT_LATLNG.longitude }}
                        options={{ gestureHandling: 'greedy' }}
                        onDragEnd={(e) => findFromLatlng(e.lat(), e.lng(), mapsRef.current)}
                        onGoogleApiLoaded={onMapsLoaded}
                    />

                    <CurrentLocation
                        className='custom-current-location-icon'
                        onGetLocation={({ lat, lng }) => findFromLatlng(lat, lng, mapsRef.current)}
                    />
                </MapsWrapper>
                <input type='hidden' name='latitude' value={addressForm.latitude} disabled />
                <input type='hidden' name='longitude' value={addressForm.longitude} disabled />
                <Row>
                    <Col xs={12} md={6}>
                        <Field.Control title='ตำบล/แขวง' name='subdistrict' value={addressForm.subdistrict ?? ''} disabled />
                    </Col>
                    <Col xs={12} md={6}>
                        <Field.Control title='อำเภอ/เขต' name='district' value={addressForm.district} disabled />
                    </Col>
                    <Col xs={12} md={6}>
                        <Field.Control title='จังหวัด' name='province' value={addressForm.province} disabled />
                    </Col>
                    <Col xs={12} md={6}>
                        <Field.Control title='รหัสไปรษณีย์' name='zipcode' value={addressForm.zipcode} disabled />
                    </Col>
                    <Col>
                        <Field.TextArea
                            title={'รายละเอียดที่อยู่'}
                            name='address'
                            rows={3}
                            placeholder={'ชื่อคอนโด ชื่อหมู่บ้าน บ้านเลขที่ ซอย/ถนน'}
                            required
                        />
                    </Col>
                </Row>
                <div className='d-flex justify-content-end pt-4'>
                    {/* {!!data?.id && (
                        <Button type='button' variant='danger' className='mr-3'>
                            <u className='cursor-pointer'>{'ลบที่อยู่'}</u>
                        </Button>
                    )} */}
                    <Button type='submit' variant='primary' loading={loading}>
                        บันทึก
                    </Button>
                </div>
            </FormField>
        </EXPPopupJs.Jsx>
    );
}

export default ModalAddEditAddress;
