import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import LiftUpV2Component from './LiftUpV2Component';
import { useLocation } from 'react-router-dom';

export type LiftUpV2ContextProps = {
    open: (props: any) => void;
    close: (id: any) => void;
    destroy: () => void;
    update: (props: any) => void;
};
export const LiftUpV2Context = createContext<LiftUpV2ContextProps>(null!);

function LiftUpV2Provider({ children }: { children: React.ReactNode }) {
    const location = useLocation();
    const [liftUpList, setLiftUpList] = useState<any[]>([]);
    // console.log('liftUpList:', liftUpList);
    const [createCount, setCreateCount] = useState<number>(0);
    const cn = useRef(0);

    const destroyLiftUp = () => {
        // console.log('destroyLiftUp');
        setLiftUpList([]);
    };
    const updateLiftUp = (props: any) => {
        console.log('props:', props);

        // const liftIndex = liftUpList.findIndex((f) => f.id === props.id);

        // liftUpList[liftIndex] = props;

        let temp = [...liftUpList];
        console.log('liftUpList:', liftUpList);

        const liftIndex = temp.findIndex((f) => f.id === props.id);
        console.log('temp:', temp);

        if (liftIndex < 0) return;
        console.log('liftIndex:', liftIndex);

        // temp[liftIndex] = [...props];
        temp.splice(liftIndex, 1, { ...props });

        // setLiftUpList([...temp]);
    };
    const closeLiftUp = (id: any) => {
        // console.log('closeLiftUp', id);
        const temp = [...liftUpList];
        const liftIndex = liftUpList.findIndex((f) => f.id === id);

        temp.splice(liftIndex, 1);

        // console.log('temp:', temp);
        setLiftUpList((prev) => {
            // console.log('prev:', prev);

            if (prev.length <= 0) return [];

            return [...temp];
        });
    };

    const openLiftUp = (props: any) => {
        // console.log('openLiftUp');
        cn.current += 1;

        setLiftUpList((prev) => [...prev, { ...props, id: cn.current }]);

        return { ...props, id: cn.current };
    };

    const values: LiftUpV2ContextProps = {
        open: openLiftUp,
        close: closeLiftUp,
        destroy: destroyLiftUp,
        update: updateLiftUp,
    };

    // destroy
    // useEffect(() => {
    //     console.log('destroy');
    //     return () => {
    //         setLiftUpList([]);
    //     };
    // }, [location.pathname]);

    return (
        <LiftUpV2Context.Provider value={values}>
            {children}
            {liftUpList.map((props, index) => (
                <LiftUpV2Component key={index} {...props} close={() => closeLiftUp(props.id)} />
            ))}
        </LiftUpV2Context.Provider>
    );
}

export default LiftUpV2Provider;
