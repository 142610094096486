// IMAGES_CONFIG_WITH_THEME["pageName"]["variableName"]["themeName"]
export const IMAGES_CONFIG_WITH_THEME = {
    existing: {
        female: {
            default: '/images/phr/existing-gender-female.png',
            workplaze: '/images/workplaze/phr/existing-gender-female.png',
        },
        male: {
            default: '/images/phr/existing-gender-male.png',
            workplaze: '/images/workplaze/phr/existing-gender-male.png',
        },
        none: {
            default: '/images/phr/existing-gender-none.png',
            workplaze: '/images/workplaze/phr/existing-gender-none.png',
        },
    },
    phr_new_gender: {
        female: {
            default: '/images/phr/phr-new-female.png',
            workplaze: '/images/workplaze/phr/phr-new-female.png',
        },
        male: {
            default: '/images/phr/phr-new-male.png',
            workplaze: '/images/workplaze/phr/phr-new-male.png',
        },
    },
    select_address: {
        btn_pharmacist: {
            default: '/images/select-address/address-btn-pharmacist.png',
            workplaze: '/images/workplaze/select-address/address-btn-pharmacist.png',
        },
    },
    wait_to_find: {
        find_telepharmacy: {
            default: '/images/wait-to-find/wait-find-telepharmacy.png',
            workplaze: '/images/workplaze/wait-to-find/wait-find-telepharmacy.png',
        },
    },
    join_call: {
        user: {
            default: '/images/join-call/join-call-icon-user.png',
            workplaze: '/images/workplaze/join-call/join-call-icon-user.png',
        },
        pharmacist: {
            default: '/images/join-call/join-call-icon-pharmacist.png',
            workplaze: '/images/workplaze/join-call/join-call-icon-pharmacist.png',
        },
        waiting_join_call_question: {
            default: '/images/join-call/waiting-join-call-question-bgc.webp',
            workplaze: '/images/workplaze/join-call/waiting-join-call-question-bgc.png',
        },
        doctor_join_call: {
            default: '/images/join-call/doctor-join-call.png',
            workplaze: '/images/workplaze/join-call/join-call-icon-tlm.png',
        },
        waiting_tlm: {
            default: '/images/join-call/join-call-icon-tlm.png',
            workplaze: '/images/workplaze/join-call/join-call-icon-tlm.png',
        },
        waiting_mth: {
            default: '/images/join-call/join-call-icon-tlm.png',
            workplaze: '/images/workplaze/join-call/join-call-icon-tlm.png',
        },
        waiting_physiotherapy: {
            default: '/images/join-call/join-call-icon-physiotherapy.png',
            workplaze: '/images/workplaze/join-call/join-call-icon-physiotherapy.png',
        },
        waiting_nutritionist: {
            default: '/images/join-call/join-call-icon-nutritionist.png',
            workplaze: '/images/workplaze/join-call/join-call-icon-nutritionist.png',
        },
        reading_tlm: {
            default: '/images/join-call/join-call-doctor.gif',
            workplaze: '/images/workplaze/join-call/join-call-doctor.png',
        },
        reading_mth: {
            default: '/images/join-call/join-call-doctor.gif',
            workplaze: '/images/workplaze/join-call/join-call-doctor.png',
        },
        reading_physiotherapy: {
            default: '/images/join-call/join-call-physiotherapy.png',
            workplaze: '/images/workplaze/join-call/join-call-icon-physiotherapy.png',
        },
        reading_nutritionist: {
            default: '/images/join-call/join-call-nutritionist.png',
            workplaze: '/images/workplaze/join-call/join-call-nutritionist.png',
        },
        ready_tlm: {
            default: '/images/join-call/join-call-tlm.png',
            workplaze: '/images/workplaze/join-call/join-call-tlm.png',
        },
        ready_mth: {
            default: '/images/join-call/join-call-tlm.png',
            workplaze: '/images/workplaze/join-call/join-call-tlm.png',
        },
        ready_physiotherapy: {
            default: '/images/join-call/join-call-physiotherapy.png',
            workplaze: '/images/workplaze/join-call/join-call-physiotherapy.png',
        },
        ready_nutritionist: {
            default: '/images/join-call/join-call-nutritionist.png',
            workplaze: '/images/workplaze/join-call/join-call-nutritionist.png',
        },
    },
    srvmed_order: {
        advice_pharmacist: {
            default: '/images/service-srv/advice-pharmacist.png',
            workplaze: '/images/workplaze/service-srv/advice-pharmacist.png',
        },
        advice_doctor: {
            default: '/images/common/advice-doctor.png',
            workplaze: '/images/workplaze/common/advice-doctor.png',
        },
        advice_pt: {
            default: '/images/service-srv/advice-pt.png',
            workplaze: '/images/workplaze/service-srv/advice-pt.png',
        },
        advice_nt: {
            default: '/images/service-srv/advice-nt.png',
            workplaze: '/images/workplaze/service-srv/advice-nt.png',
        },
        advice_pharmacist_wait: {
            default: '/images/service-srv/advice-pharmacist-wait.png',
            workplaze: '/images/service-srv/advice-pharmacist-wait.png',
        },
        successful_payment: {
            default: '/images/common/successful-payment.png',
            workplaze: '/images/workplaze/common/successful-payment.png',
        },
    },
    srvmed_finish: {
        finish: {
            default: '/images/service-srv/srvmed-finish.png',
            workplaze: '/images/workplaze/service-srv/srvmed-finish.png',
            central: '/images/service-srv/topscare.png',
            fastcare: '/images/service-srv/fastcare.png',
            yaphrom: '/images/logo/yaphrom-logo.png',
            livewell: '/images/logo/livewell_ho.png',
        },
        finish_header: {
            default: '/images/service-srv/srvmed-finish-header.png',
            workplaze: '/images/workplaze/service-srv/srvmed-finish-header.png',
            central: '/images/service-srv/topscare.png',
            fastcare: '/images/service-srv/fastcare.png',
            yaphrom: '/images/logo/yaphrom-logo.png',
            livewell: '/images/logo/livewell_ho.png',
        },
    },
    select_hsp: {
        header: {
            default: '/images/select-hsp/select-hsp-header.webp',
            workplaze: '/images/workplaze/select-hsp/select-hsp-header.png',
        },
    },
    pending_tlm: {
        pending_med: {
            default: '/images/service-med/pending-med-tlm.png',
            workplaze: '/images/workplaze/service-med/pending-med-tlm.png',
        },
        popup_pending: {
            default: '/images/service-med/popup-pending.png',
            workplaze: '/images/workplaze/service-med/popup-pending.png',
        },
    },
    pending_ful: {
        pharmacist: {
            default: '/images/common/pharmacist.png',
            workplaze: '/images/workplaze/common/pharmacist.png',
        },
    },
    payment: {
        no_payment: {
            default: '/images/payment/no-payment.png',
            workplaze: '/images/workplaze/payment/no-payment.png',
        },
        payment: {
            default: '/images/payment/payment.png',
            workplaze: '/images/workplaze/payment/payment.png',
        },
    },
    appointment_confirm: {
        appointment_confirm: {
            default: '/images/appointment/appointment-confirm.png',
            workplaze: '/images/workplaze/appointment/appointment-confirm.png',
        },
    },
    service: {
        service_empty: {
            default: '/images/common/service-empty.png',
            workplaze: '/images/workplaze/common/service-empty.png',
        },
    },
    store: {
        default_pharmacist: {
            default: '/images/pharmacy/default-pharmacist.png',
            workplaze: '/images/workplaze/pharmacy/default-pharmacist.png',
        },
        store_detail: {
            default: '/images/pharmacy/store-detail.png',
            workplaze: '/images/workplaze/pharmacy/store-detail.png',
        },
    },
    reject_task: {
        reject: {
            default: '/images/common/claim-not-found.png',
            workplaze: '/images/workplaze/common/claim-not-found.png',
        },
    },
};

export const LOGO_IMAGE_WITH_THEME = {
    default: {
        th: '/images/logo/pharmcare-logo.webp',
        en: '/images/logo/pharmcare-logo.webp',
    },
    cpa: {
        th: '/images/logo/pharmcare-logo.webp',
        en: '/images/logo/pharmcare-logo.webp',
    },
    no_config: {
        th: '/images/logo/pharmcare-logo.webp',
        en: '/images/logo/pharmcare-logo.webp',
    },
    default_pharmcareads: {
        th: '/images/logo/pharmcare-logo.webp',
        en: '/images/logo/pharmcare-logo.webp',
    },
    default_humatrix: {
        th: '/images/logo/pharmcare-logo.webp',
        en: '/images/logo/pharmcare-logo.webp',
    },
    default_oceanopd: {
        th: '/images/logo/pharmcare-logo.webp',
        en: '/images/logo/pharmcare-logo.webp',
    },
    default_sunday: {
        th: '/images/logo/pharmcare-logo.webp',
        en: '/images/logo/pharmcare-logo.webp',
    },
    partner_pharmacy: {
        th: '/images/logo/pharmcare-logo.webp',
        en: '/images/logo/pharmcare-logo.webp',
    },
    doctorhub: {
        th: '/images/logo/pharmcare-logo.webp',
        en: '/images/logo/pharmcare-logo.webp',
    },
    ptg: {
        th: '/images/logo/pharmcare-logo.webp',
        en: '/images/logo/pharmcare-logo.webp',
    },
    central: {
        th: '/images/logo/tops-care2.png',
        en: '/images/logo/tops-care2.png',
    },
    central20: {
        th: '/images/logo/tops-care2.png',
        en: '/images/logo/tops-care2.png',
    },
    central30: {
        th: '/images/logo/tops-care2.png',
        en: '/images/logo/tops-care2.png',
    },
    benix: {
        th: '/images/logo/benix-logo.jpg',
        en: '/images/logo/benix-logo.jpg',
    },
    betterperiod: {
        th: '/images/logo/better-logo.png',
        en: '/images/logo/better-logo.png',
    },
    fastcare: {
        th: '/images/logo/fast-care.png',
        en: '/images/logo/fast-care.png',
    },
    rabbit: {
        th: '/images/logo/rabbit-logo-th.webp',
        en: '/images/logo/rabbit-logo-en.webp',
    },
    workplaze: {
        th: '/images/logo/workplaze.png',
        en: '/images/logo/workplaze.png',
    },
    yaphrom: {
        th: '/images/logo/yaphrom-logo.png',
        en: '/images/logo/yaphrom-logo.png',
    },
    humatrix: {
        th: '/images/logo/pharmcare-logo.webp',
        en: '/images/logo/pharmcare-logo.webp',
        // th: '/images/logo/humatrix-logo.png',
        // en: '/images/logo/humatrix-logo.png'
    },
    pandf: {
        th: '/images/logo/pandf-logo.png',
        en: '/images/logo/pandf-logo.png',
    },
    morningmind: {
        th: '/images/logo/morningmind-logo.png',
        en: '/images/logo/morningmind-logo.png',
    },
    livewell: {
        th: '/images/logo/livewell_ho.png',
        en: '/images/logo/livewell_ho.png',
    },
};

export const MAP_ICON = {
    marker: '/images/maps/marker-here.svg',
    pharmacy: {
        open: {
            default: '/images/maps/pharmacy-open.png',
            hover: '/images/maps/pharmacy-open-hover.png',
        },
        close: {
            default: '/images/maps/pharmacy-close.png',
            hover: '/images/maps/pharmacy-close-hover.png',
        },
        quality: {
            default: '/images/maps/pharmacy-open-quality.png',
            hover: '/images/maps/pharmacy-open-quality-hover.png',
        },
    },
};
