import { extendesClassname } from 'utils';
import { LiftUpWrapper } from './LiftUpV2.styled';
import { CSSProperties, useEffect, useMemo, useState } from 'react';

export type LiftUpV2Props = {
    id: string | number;
    show?: boolean | false;
    showClose?: boolean | false;
    isClickOutSideClose: boolean | true;
    onlyLiftup?: boolean | false;
    liftSize?: 'XS' | 'SM' | 'MD' | 'LG' | 'XL' | 'XXL' | 'XXXL';
    maxWidth?: string;
    backgroundColor?: string;
    zIndex?: number | 999;
    className?: string;
    style?: CSSProperties;
    children?: React.ReactNode;
    close: (id: string | number) => void;
    onClose: () => void;
};
function LiftUpV2Component({
    id,
    show,
    showClose,
    isClickOutSideClose = true,
    onlyLiftup = false,
    zIndex,
    backgroundColor,
    liftSize = 'XS',
    maxWidth,
    close,
    onClose,
    className,
    children,
    ...props
}: LiftUpV2Props) {
    const [isExpaned, setIsExpaned] = useState(false);

    const adjustContainerStyle = useMemo(() => {
        let _adjustContainerStyle = {};

        if (backgroundColor) _adjustContainerStyle = { ..._adjustContainerStyle, backgroundColor: backgroundColor };
        if (maxWidth) _adjustContainerStyle = { ..._adjustContainerStyle, maxWidth: maxWidth };

        return _adjustContainerStyle;
    }, [backgroundColor, maxWidth]);

    const adjustZIndex = useMemo(() => {
        let _adjustZIndex = {};

        if (zIndex) _adjustZIndex = { ..._adjustZIndex, zIndex: zIndex };

        return _adjustZIndex;
    }, [zIndex]);

    const onClickOutsideClose = () => {
        if (!isClickOutSideClose) return;

        onCloseLiftup();
    };

    const onCloseLiftup = (onlyClose: boolean | undefined = false) => {
        setIsExpaned(false);

        if (!onlyClose && onClose) {
            // console.log('onClose:', onClose);
            onClose();
        }

        setTimeout(() => {
            if (close) close(id);
        }, 300);
    };

    useEffect(() => {
        let timer: NodeJS.Timeout = null!;
        const onShow = () => {
            if (show) {
                timer = setTimeout(() => {
                    setIsExpaned(true);
                }, 300);
            } else if (isExpaned) {
                onCloseLiftup(true);
            }
        };

        onShow();

        return () => clearTimeout(timer);
    }, [show]);

    return (
        <LiftUpWrapper
            // ref={ref}
            liftSize={liftSize as string}
            {...extendesClassname([isExpaned && 'show', className])}
            {...props}
            style={{ ...props.style, ...adjustZIndex }}
        >
            <div className='exp-popup-slideup-overlay' onClick={onClickOutsideClose} />
            <div className='exp-popup-slideup-container' style={{ ...adjustContainerStyle }}>
                {showClose && <div className='exp-popup-slideup-close-icon' onClick={() => onCloseLiftup()} />}
                <div className='exp-popup-slideup-body scrollbar'>{children}</div>
            </div>
        </LiftUpWrapper>
    );
}

export default LiftUpV2Component;
